<template>
  <div class="dialog">
    <!-- 广告主媒体切换 -->
    <div
      v-if="classification"
      class="dialog-content dialog-tab"
    >
      <img
        src="../img/dialog-close.png"
        alt=""
        class="dialog-close"
        @click="handleClickClose"
      >
      <div class="dialog-title">
        请正确选择角色后注册/登录
      </div>
      <div class="dialog-option-list">
        <div
          class="dialog-option-item"
          @click="changeTab(0)"
        >
          <div class="dialog-option-img">
            <img
              src="../img/dialog-option-img.png"
              alt=""
            >
          </div>
          <div class="dialog-option-name">
            我是媒体主
          </div>
          <div class="dialog-option-name-bar">
            （流量增益）
          </div>
        </div>
        <div
          class="dialog-option-item"
          @click="changeTab(1)"
        >
          <div class="dialog-option-img">
            <img
              src="../img/dialog-option-img2.png"
              alt=""
            >
          </div>
          <div class="dialog-option-name">
            我是广告主
          </div>
          <div class="dialog-option-name-bar">
            （广告投放）
          </div>
        </div>
      </div>
    </div>
    <!-- 登录注册 -->
    <!-- <div
      class="dialog-content"
    > -->
    <!-- <img
        src="../img/dialog-close.png"
        alt=""
        class="dialog-close"
        @click="handleClickClose"
      >
      <div class="dialog-title">
        <div
          @click="handleChangeTab(0)"
          :class="{active: dialogTab===0}"
        >
          媒体{{ status==0?'登录':'注册' }}
        </div>
        <div
          @click="handleChangeTab(1)"
          :class="{active: dialogTab===1}"
        >
          广告主{{ status==0?'登录':'注册' }}
        </div>
      </div> -->
    <!-- 媒体登录 -->
    <!-- <div
        class="login"
        v-if="status==0&&dialogTab===0"
      >
        <div class="login-input phone">
          <input
            v-model="loginForm.mobile"
            placeholder="请输入手机号"
          >
        </div>
        <div
          v-if="loginType=='password'"
          class="login-input pwd"
        >
          <input
            ref="password"
            v-model="loginForm.mediaPassword"
            placeholder="请输入密码"
            :type="passwordType"
          >
          <img
            @click="showPwd"
            v-if="passwordType==''"
            class="login-input-icon"
            src="../img/pwd-show.png"
            alt=""
          >
          <img
            @click="showPwd"
            v-if="passwordType=='password'"
            class="login-input-icon"
            src="../img/pwd-hidden.png"
            alt=""
          >
        </div>
        <div
          v-else
          class="login-input code"
        >
          <input
            v-model="loginForm.code"
            placeholder="请输入验证码"
          >
          <div
            v-if="!codeStatus"
            @click="handleClickGetCode"
            class="login-input-code"
          >
            {{ codeText }}
          </div>
          <div
            v-else
            class="login-input-code active"
          >
            {{ codeTime }}秒后重新发送
          </div>
        </div>
        <div class="login-info">
          <img
            @click="handleClickRememberPhone"
            class="login-info-icon"
            v-if="loginRememberPhone"
            src="../img/dialog-unchecked.png"
            alt=""
          >
          <img
            @click="handleClickRememberPhone"
            v-else
            class="login-info-icon"
            src="../img/dialog-checked.png"
            alt=""
          >
          <div class="login-info-text">
            记住手机号
          </div>
          <div
            @click="handleChangeLoginType"
            class="login-info-sign"
          >
            {{ loginType=='password'?'短信验证码':'密码' }}登录
          </div>
        </div>
      </div> -->
    <!-- 媒体注册 -->
    <!-- <div
        v-if="status===1&&dialogTab===0"
        class="reg center"
      >
        <div class="login-input phone">
          <input
            v-model="loginForm.mobile"
            placeholder="请输入手机号"
          >
        </div>
        <div
          class="login-input code"
        >
          <input
            v-model="loginForm.code"
            placeholder="请输入验证码"
          >
          <div
            v-if="!codeStatus"
            @click="handleClickGetCode"
            class="login-input-code"
          >
            {{ codeText }}
          </div>
          <div
            v-else
            class="login-input-code active"
          >
            {{ codeTime }}秒后重新发送
          </div>
        </div>
        <div
          class="login-input pwd"
        >
          <input
            ref="password"
            v-model="loginForm.mediaPassword"
            placeholder="请输入密码"
            :type="passwordType"
          >
          <img
            @click="showPwd"
            v-if="passwordType==''"
            class="login-input-icon"
            src="../img/pwd-show.png"
            alt=""
          >
          <img
            @click="showPwd"
            v-if="passwordType=='password'"
            class="login-input-icon"
            src="../img/pwd-hidden.png"
            alt=""
          >
        </div>
        
        <div class="login-info">
          <img
            @click="handleClickAgreeRule"
            class="login-info-icon"
            v-if="!ruleChecked"
            src="../img/dialog-unchecked.png"
            alt=""
          >
          <img
            @click="handleClickAgreeRule"
            v-else
            class="login-info-icon"
            src="../img/dialog-checked.png"
            alt=""
          >
          <div class="login-info-text">
            我已阅读并同意<span @click="handleClickRule">《新量象服务条款》</span>
          </div>
        </div>
      </div> -->
    <!-- 广告主登录 -->
    <!-- <div
        class="login"
        v-if="status===0&&dialogTab===1"
      >
        <div class="login-input phone">
          <input
            v-model="loginFormVoice.mobile"
            placeholder="请输入手机号"
          >
        </div>
        <div
          v-if="loginType=='password'"
          class="login-input pwd"
        >
          <input
            ref="password"
            v-model="loginFormVoice.password"
            placeholder="请输入密码"
            :type="passwordType"
          >
          <img
            @click="showPwd"
            v-if="passwordType==''"
            class="login-input-icon"
            src="../img/pwd-show.png"
            alt=""
          >
          <img
            @click="showPwd"
            v-if="passwordType=='password'"
            class="login-input-icon"
            src="../img/pwd-hidden.png"
            alt=""
          >
        </div>
        <div
          v-else
          class="login-input code"
        >
          <input
            v-model="loginFormVoice.code"
            placeholder="请输入验证码"
          >
          <div
            v-if="!codeStatus"
            @click="handleClickGetCodeVoice"
            class="login-input-code"
          >
            {{ codeText }}
          </div>
          <div
            v-else
            class="login-input-code active"
          >
            {{ codeTime }}秒后重新发送
          </div>
        </div>
        <div class="login-info">
          <img
            @click="handleClickRememberPhone"
            class="login-info-icon"
            v-if="loginRememberPhone"
            src="../img/dialog-unchecked.png"
            alt=""
          >
          <img
            @click="handleClickRememberPhone"
            v-else
            class="login-info-icon"
            src="../img/dialog-checked.png"
            alt=""
          >
          <div class="login-info-text">
            记住手机号
          </div>
          <div
            @click="handleChangeLoginType"
            class="login-info-sign"
          >
            {{ loginType=='password'?'短信验证码':'密码' }}登录
          </div>
        </div>
      </div> -->
    <!-- 广告主注册 -->
    <!-- <div
        v-if="status===1&&dialogTab===1"
        class="reg center"
      >
        <div class="login-input phone">
          <input
            v-model="loginFormVoice.mobile"
            placeholder="请输入手机号"
          >
        </div>
        <div
          class="login-input code"
        >
          <input
            v-model="loginFormVoice.code"
            placeholder="请输入验证码"
          >
          <div
            v-if="!codeStatus"
            @click="handleClickGetCodeVoice"
            class="login-input-code"
          >
            {{ codeText }}
          </div>
          <div
            v-else
            class="login-input-code active"
          >
            {{ codeTime }}秒后重新发送
          </div>
        </div>
        <div
          class="login-input pwd"
        >
          <input
            ref="password"
            v-model="loginFormVoice.password"
            placeholder="请输入密码"
            :type="passwordType"
          >
          <img
            @click="showPwd"
            v-if="passwordType==''"
            class="login-input-icon"
            src="../img/pwd-show.png"
            alt=""
          >
          <img
            @click="showPwd"
            v-if="passwordType=='password'"
            class="login-input-icon"
            src="../img/pwd-hidden.png"
            alt=""
          >
        </div>
        
        <div class="login-info">
          <img
            @click="handleClickAgreeRule"
            class="login-info-icon"
            v-if="!ruleChecked"
            src="../img/dialog-unchecked.png"
            alt=""
          >
          <img
            @click="handleClickAgreeRule"
            v-else
            class="login-info-icon"
            src="../img/dialog-checked.png"
            alt=""
          >
          <div class="login-info-text">
            我已阅读并同意<span @click="handleClickRule">《新量象服务条款》</span>
          </div>
        </div>
      </div> -->
    <!-- 广告主注册二维码版本 -->
    <!-- <div
        class="reg"
        v-if="status===1&&dialogTab===1"
      >
        <div class="reg-name">
          商务 吴伟伟：
        </div>
        <div class="reg-desc">
          您好，我是新量象商务对接人，为您提供更优质的商务化解决方案，请与我建立联系。
        </div>
        <img
          class="reg-qrcode"
          src="../img/qrcode1.png"
        >
        <div class="reg-concat">
          ( QQ：<span>12347857</span>  /  手机号：<span>13774958884</span> )
        </div>
      </div> -->
    <!-- <div
        
        @click="handleClickLogin"
        class="dialog-btn"
      >
        {{ status== 0?'登录': '注册' }}
      </div>
      <div class="dialog-sign">
        {{ status==0?'还没有':'已有' }}账号？<span @click="handleChangeStatus">{{ status==0?'马上注册':'马上登录' }}</span>
      </div> -->
    <!-- </div> -->

    <div
      v-if="!classification"
      class="dialog-content"
    >
      <img
        src="../img/dialog-close.png"
        alt=""
        class="dialog-close"
        @click="handleClickClose"
      >
      <div class="dialog-title">
        <div
          @click="handleChangeTab(0)"
          :class="{active: dialogTab===0}"
        >
          {{ status==0?'媒体':'广告主' }}登录
        </div>
        <div
          @click="handleChangeTab(1)"
          :class="{active: dialogTab===1}"
        >
          {{ status==0?'媒体':'广告主' }}注册
        </div>
      </div>
      <!-- 媒体登录 -->
      <div
        class="login"
        v-if="status==0&&dialogTab===0"
      >
        <div class="login-input phone">
          <input
            v-model="loginForm.mobile"
            placeholder="请输入手机号"
          >
        </div>
        <div
          v-if="loginType=='password'"
          class="login-input pwd"
        >
          <input
            ref="password"
            v-model="loginForm.mediaPassword"
            placeholder="请输入密码"
            :type="passwordType"
          >
          <img
            @click="showPwd"
            v-if="passwordType==''"
            class="login-input-icon"
            src="../img/pwd-show.png"
            alt=""
          >
          <img
            @click="showPwd"
            v-if="passwordType=='password'"
            class="login-input-icon"
            src="../img/pwd-hidden.png"
            alt=""
          >
        </div>
        <div
          v-else
          class="login-input code"
        >
          <input
            v-model="loginForm.code"
            placeholder="请输入验证码"
          >
          <div
            v-if="!codeStatus"
            @click="handleClickGetCode"
            class="login-input-code"
          >
            {{ codeText }}
          </div>
          <div
            v-else
            class="login-input-code active"
          >
            {{ codeTime }}秒后重新发送
          </div>
        </div>
        <div class="login-info">
          <img
            @click="handleClickRememberPhone"
            class="login-info-icon"
            v-if="loginRememberPhone"
            src="../img/dialog-unchecked.png"
            alt=""
          >
          <img
            @click="handleClickRememberPhone"
            v-else
            class="login-info-icon"
            src="../img/dialog-checked.png"
            alt=""
          >
          <div class="login-info-text">
            记住手机号
          </div>
          <div
            @click="handleChangeLoginType"
            class="login-info-sign"
          >
            {{ loginType=='password'?'短信验证码':'密码' }}登录
          </div>
        </div>
      </div>
      <!-- 媒体注册 -->
      <div
        v-if="status===0&&dialogTab===1"
        class="login"
      >
        <div class="login-input phone">
          <input
            v-model="loginForm.mobile"
            placeholder="请输入手机号"
          >
        </div>
        <div
          class="login-input code"
        >
          <input
            v-model="loginForm.code"
            placeholder="请输入验证码"
          >
          <div
            v-if="!codeStatus"
            @click="handleClickGetCode"
            class="login-input-code"
          >
            {{ codeText }}
          </div>
          <div
            v-else
            class="login-input-code active"
          >
            {{ codeTime }}秒后重新发送
          </div>
        </div>
        <div
          class="login-input pwd"
        >
          <input
            ref="password"
            v-model="loginForm.mediaPassword"
            placeholder="请输入密码"
            :type="passwordType"
          >
          <img
            @click="showPwd"
            v-if="passwordType==''"
            class="login-input-icon"
            src="../img/pwd-show.png"
            alt=""
          >
          <img
            @click="showPwd"
            v-if="passwordType=='password'"
            class="login-input-icon"
            src="../img/pwd-hidden.png"
            alt=""
          >
        </div>
        
        <div class="login-info">
          <img
            @click="handleClickAgreeRule"
            class="login-info-icon"
            v-if="!ruleChecked"
            src="../img/dialog-unchecked.png"
            alt=""
          >
          <img
            @click="handleClickAgreeRule"
            v-else
            class="login-info-icon"
            src="../img/dialog-checked.png"
            alt=""
          >
          <div class="login-info-text">
            我已阅读并同意<span @click="handleClickRule">《新量象服务条款》</span>
          </div>
        </div>
      </div>
      <!-- 广告主登录 -->
      <div
        class="reg center"
        v-if="status===1&&dialogTab===0"
      >
        <div class="login-input phone">
          <input
            v-model="loginFormVoice.mobile"
            placeholder="请输入手机号"
          >
        </div>
        <div
          v-if="loginType=='password'"
          class="login-input pwd"
        >
          <input
            ref="password"
            v-model="loginFormVoice.password"
            placeholder="请输入密码"
            :type="passwordType"
          >
          <img
            @click="showPwd"
            v-if="passwordType==''"
            class="login-input-icon"
            src="../img/pwd-show.png"
            alt=""
          >
          <img
            @click="showPwd"
            v-if="passwordType=='password'"
            class="login-input-icon"
            src="../img/pwd-hidden.png"
            alt=""
          >
        </div>
        <div
          v-else
          class="login-input code"
        >
          <input
            v-model="loginFormVoice.code"
            placeholder="请输入验证码"
          >
          <div
            v-if="!codeStatus"
            @click="handleClickGetCodeVoice"
            class="login-input-code"
          >
            {{ codeText }}
          </div>
          <div
            v-else
            class="login-input-code active"
          >
            {{ codeTime }}秒后重新发送
          </div>
        </div>
        <div class="login-info">
          <img
            @click="handleClickRememberPhone"
            class="login-info-icon"
            v-if="loginRememberPhone"
            src="../img/dialog-unchecked.png"
            alt=""
          >
          <img
            @click="handleClickRememberPhone"
            v-else
            class="login-info-icon"
            src="../img/dialog-checked.png"
            alt=""
          >
          <div class="login-info-text">
            记住手机号
          </div>
          <div
            @click="handleChangeLoginType"
            class="login-info-sign"
          >
            {{ loginType=='password'?'短信验证码':'密码' }}登录
          </div>
        </div>
      </div>
      <!-- 广告主注册 -->
      <div
        v-if="status===1&&dialogTab===1"
        class="reg center"
      >
        <div class="login-input phone">
          <input
            v-model="loginFormVoice.mobile"
            placeholder="请输入手机号"
          >
        </div>
        <div
          class="login-input code"
        >
          <input
            v-model="loginFormVoice.code"
            placeholder="请输入验证码"
          >
          <div
            v-if="!codeStatus"
            @click="handleClickGetCodeVoice"
            class="login-input-code"
          >
            {{ codeText }}
          </div>
          <div
            v-else
            class="login-input-code active"
          >
            {{ codeTime }}秒后重新发送
          </div>
        </div>
        <div
          class="login-input pwd"
        >
          <input
            ref="password"
            v-model="loginFormVoice.password"
            placeholder="请输入密码"
            :type="passwordType"
          >
          <img
            @click="showPwd"
            v-if="passwordType==''"
            class="login-input-icon"
            src="../img/pwd-show.png"
            alt=""
          >
          <img
            @click="showPwd"
            v-if="passwordType=='password'"
            class="login-input-icon"
            src="../img/pwd-hidden.png"
            alt=""
          >
        </div>
        <div class="login-info">
          <img
            @click="handleClickAgreeRule"
            class="login-info-icon"
            v-if="!ruleChecked"
            src="../img/dialog-unchecked.png"
            alt=""
          >
          <img
            @click="handleClickAgreeRule"
            v-else
            class="login-info-icon"
            src="../img/dialog-checked.png"
            alt=""
          >
          <div class="login-info-text">
            我已阅读并同意<span @click="handleClickRule">《新量象服务条款》</span>
          </div>
        </div>
      </div>
      <!-- 广告主注册二维码版本 -->
      <!-- <div
        class="reg"
        v-if="status===1&&dialogTab===1"
      >
        <div class="reg-name">
          商务 吴伟伟：
        </div>
        <div class="reg-desc">
          您好，我是新量象商务对接人，为您提供更优质的商务化解决方案，请与我建立联系。
        </div>
        <img
          class="reg-qrcode"
          src="../img/qrcode1.png"
        >
        <div class="reg-concat">
          ( QQ：<span>12347857</span>  /  手机号：<span>13774958884</span> )
        </div>
      </div> -->
      <div
        
        @click="handleClickLogin"
        class="dialog-btn"
      >
        {{ dialogTab== 0?'登录': '注册' }}
      </div>
      <!-- <div class="dialog-sign">
        {{ status==0?'还没有':'已有' }}账号？<span @click="handleChangeStatus">{{ status==0?'马上注册':'马上登录' }}</span>
      </div> -->
    </div>
  </div>
</template>

<script>
  import { Message } from 'element-ui'
  import { login, register, getCode } from '@/api'
  import { getVoiceCode, registerVoice, voiceLogin } from '@/api/voice'
  export default {
    data () {
      return {
        status: 0, // 0媒体  1 广告主
        dialogTab: 0, // 0登录  1注册
        loginForm: {
          mobile: localStorage.getItem('phone')?localStorage.getItem('phone'):'',
          mediaPassword: '',
          code: null,
          loginType: 0
        },
        loginType: 'password',
        loginRememberPhone: localStorage.getItem('phoneRememberStatus') || false,
        passwordType: 'password',
        codeStatus: false,
        ruleChecked: localStorage.getItem('ruleChecked') || false,
        codeTime: 120,
        codeText: '获取验证码',
        codeT: null,
        //语音红包
        loginFormVoice:{
          mobile: localStorage.getItem('phoneVoice')?localStorage.getItem('phoneVoice'):'',
          password: '',
          code: null,
          loginType: 0
        },

        classification:true //媒体 广告主切换
      }
    },
    methods: {
      handleClickClose() {
        this.$emit('handleClickClose')
      },
      iniTimer () {
        clearInterval(this.codeT)
        this.codeTime = 120
        this.codeStatus = false
        this.codeText = '获取验证码'
      },
      handleChangeTab(type) {
        if (this.dialogTab == type) return false
        this.iniTimer()
        // if (type == 1&&this.status===0) {
        //   this.$message('敬请期待！')
        //   return false
        // }
        this.codeStatus = false
        this.dialogTab = type
      },
      showPwd() {
        if (this.passwordType === 'password') {
          this.passwordType = ''
        } else {
          this.passwordType = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      handleClickRememberPhone() {
        this.loginRememberPhone = !this.loginRememberPhone
        localStorage.setItem('phoneRememberStatus', this.loginRememberPhone)
      },
      handleChangeStatus() {
        this.status==0?this.status=1:this.status=0
        this.codeStatus = false
        this.iniTimer()
      },
      async handleClickGetCode() {
        // 获取验证码
        if (this.loginForm.mobile == '') {
          Message({
            message: '请输入正确的手机号',
            type: 'error',
            duration: 5 * 1000
          })
          return false
        }
        this.codeStatus = true
        const res = await getCode(this.loginForm.mobile)
        if (res.code === 200) {
          Message({
            message: '验证码发送成功，请注意来电！',
            type: 'success',
            duration: 5 * 1000
          })
          this.codeT = setInterval(() => {
            this.codeTime--
            if (this.codeTime < 1) {
              clearInterval(this.codeT)
              this.codeTime = 120
              this.codeStatus = false
              this.codeText = '重新发送'
            }
          }, 1000);
        } else {
          Message({
            message: res.msg,
            type: 'error',
            duration: 5 * 1000
          })
        }
      },
      handleChangeLoginType() {
        if (this.loginType === 'password') {
          this.loginForm.loginType = 1
          this.loginFormVoice.loginType = 1
          this.loginType = 'code'
        } else {
          this.loginForm.loginType = 0
          this.loginFormVoice.loginType = 0
          this.loginType = 'password'
        }
      },
      handleClickAgreeRule() {
        this.ruleChecked = !this.ruleChecked
        localStorage.setItem('ruleChecked', this.ruleChecked)
      },
      async handleClickLogin() {
        // status 0 媒体  1 广告主 
        // dialogTab 0 登录 1 注册
        if(this.status===0){
          if (this.dialogTab === 0) {
            const res = await login(this.loginForm)
            if (res.code === 200) {
              localStorage.setItem('phone', this.loginForm.mobile)
              this.$emit('loginSuccess')
              this.loginForm = {
                mobile: '',
                mediaPassword: '',
                code: null,
                loginType: 0
              }
              Message({
                message: '登录成功！',
                type: 'success',
                duration: 5 * 1000
              })
            } else {
              Message({
                message: res.msg,
                type: 'error',
                duration: 5 * 1000
              })
            }
          } else {
            if (!this.ruleChecked) {
              Message({
                message: '请同意服务条款！',
                type: 'error',
                duration: 5 * 1000
              })
              return false
            }
            const res  = await register(this.loginForm)
            if (res.code === 200) {
              Message({
                message: '注册成功！',
                type: 'success',
                duration: 5 * 1000
              })
              localStorage.setItem('phone', this.loginForm.mobile)
              this.$emit('regSuccess')
              this.dialogTab = 0
              this.loginForm = {
                mobile: '',
                mediaPassword: '',
                code: null
              }
            } else {
              Message({
                message: res.msg,
                type: 'error',
                duration: 5 * 1000
              })
            }
          }
        }else{
          this.handleClickLoginVoice()
        }
        
      },
      handleClickRule () {
        this.handleClickClose()
        this.$router.push('rule')
      },


      //媒体广告主切换
      changeTab(e){
        this.classification =false
        this.status =e
      },

      //语音红包逻辑
      async handleClickGetCodeVoice (){
        // 获取验证码语音红包
        if (this.loginFormVoice.mobile == '') {
          Message({
            message: '请输入正确的手机号',
            type: 'error',
            duration: 5 * 1000
          })
          return false
        }
        this.codeStatus = true
        const res = await getVoiceCode(this.loginFormVoice.mobile)
        if (res.code === 200) {
          Message({
            message: '验证码发送成功，请注意来电！',
            type: 'success',
            duration: 5 * 1000
          })
          this.codeT = setInterval(() => {
            this.codeTime--
            if (this.codeTime < 1) {
              clearInterval(this.codeT)
              this.codeTime = 120
              this.codeStatus = false
              this.codeText = '重新发送'
            }
          }, 1000);
        } else {
          Message({
            message: res.msg,
            type: 'error',
            duration: 5 * 1000
          })
        }
      },
      //注册
      async handleClickLoginVoice() {
        // status 0 媒体  1 广告主 
        if (this.status === 1&&this.dialogTab === 0) {
          const res = await voiceLogin(this.loginFormVoice)
          if (res.code === 200) {
            localStorage.setItem('phoneVoice', this.loginFormVoice.mobile)
            this.$emit('loginSuccess',2) //2表示语音红包广告主后台登录
            this.loginFormVoice = {
              mobile: '',
              password: '',
              code: null,
              loginType: 0
            }
            Message({
              message: '登录成功！',
              type: 'success',
              duration: 5 * 1000
            })
          } else {
            Message({
              message: res.msg,
              type: 'error',
              duration: 5 * 1000
            })
          }
        } else {
          if (!this.ruleChecked) {
            Message({
              message: '请同意服务条款！',
              type: 'error',
              duration: 5 * 1000
            })
            return false
          }
          const res  = await registerVoice(this.loginFormVoice)
          if (res.code === 200) {
            Message({
              message: '注册成功！',
              type: 'success',
              duration: 5 * 1000
            })
            localStorage.setItem('phoneVoice', this.loginFormVoice.mobile)
            this.$emit('regSuccess',3)//2表示语音红包广告主后台注册
            this.dialogTab = 0
            this.loginFormVoice = {
              mobile: '',
              password: '',
              code: null
            }
          } else {
            Message({
              message: res.msg,
              type: 'error',
              duration: 5 * 1000
            })
          }
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
.dialog{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background:rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  &-content{
    position: absolute;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;
    width:470px;
    background:rgba(255,255,255,1);
    border-radius:10px;
    padding-top: 80px;
    padding-bottom: 40px;
  }
  &-close{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 19px;
    height: 19px;
    cursor: pointer;
  }
  .login{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
.dialog-title{
  display: flex;
  align-items: center;
  margin-bottom: 80px;
  div{
    font-size:22px;
    // font-family:'fzqk';
    font-weight:bold;
    color: rgba(102, 102, 102, 1);
    line-height:22px;
    cursor: pointer;
    &.active{
      position: relative;
      color:rgba(255,132,0,1);
      &:after{
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -16px;
        width:80px;
        height:3px;
        background:rgba(255,132,0,1);
        content: '';
        display: 'block';
      }
    }
  }
  div:nth-child(1){
    margin-right: 100px;
  }
}
.login-input{
  position: relative;
  width: 340px;
  &.phone{
    margin-bottom: 60px;
  }
  &.pwd,&.code{
    margin-bottom: 36px;
  }
  input{
    outline: none;
    width: 100%;
    border: none;
    border-bottom: 1px solid rgba(230, 230, 230, 1);
    padding-bottom: 12px;
    color: #333;
    font-size: 20px;
  }
  .login-input-icon{
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    right: 10px;
    width: 26px;
    height: 12px;
  }
  .login-input-code{
    position: absolute;
    cursor: pointer;
    right: 0;
    bottom: 13px;
    font-size:16px;
    color:rgba(255,132,0,1);
    line-height: 16px;
    &.active{
      color: rgba(153, 153, 153, 1);
    }
  }
}
.login-info{
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  width: 340px;
  &-icon{
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }
  &-text{
    font-size:16px;
    line-height: 16px;
    color:rgba(153,153,153,1);
    span{
      text-decoration:underline;
      color:rgba(255,132,0,1);
      cursor: pointer;
    }
  }
  &-sign{
    margin-left: auto;
    cursor: pointer;
    font-size:16px;
    line-height: 16px;
    text-decoration:underline;
    color:rgba(255,132,0,1);
  }
}
.dialog-btn{
  cursor: pointer;
  width: 320px;
  height: 89px;
  text-align: center;
  line-height: 60px;
  background-image: url('../img/dialog-btn.png');
  background-size: 100% 100%;
  margin: 0 auto 11px;
  font-size:22px;
  // font-family:'fzqk';
  color:rgba(255,255,255,1);
}
.dialog-sign{
  font-size:16px;
  line-height: 16px;
  color:rgba(153,153,153,1);
  text-align: center;
  span{
    cursor: pointer;
    color: rgba(255, 132, 0, 1);
    text-decoration: underline;
  }
}
input:-internal-autofill-selected {
  background-color: #fff !important;
  background-image: none !important;
  color: #333 !important;
}
.reg{
  width: 100%;
  &.center{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login-input{
    &.code{
      margin-bottom: 60px;
    }
  }
  &-name{
    margin-top: -20px;
    font-size:22px;
    line-height: 22px;
    margin-bottom: 15px;
    text-align: left;
    color:rgba(102,102,102,1);
    margin-left: 50px;
  }
  &-desc{
    font-size:16px;
    color:rgba(153,153,153,1);
    line-height:24px;
    margin-bottom: 25px;
    margin-left: 50px;
    width: 367px;
  }
  &-qrcode{
    width: 200px;
    height: 200px;
    margin: 0 auto 30px;
    display: block;
  }
  &-concat{
    font-size:14px;
    line-height: 14px;
    color:rgba(102,102,102,1);
    margin-bottom: 40px;
    text-align: center;
    span{
      color: rgba(255, 132, 0, 1);
    }
  }
}
.dialog-tab{
  .dialog-title{
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
    margin-bottom: 25px;
  }
  .dialog-option-list{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 20px;
    .dialog-option-item{
      width: 113px;
      height: 147px;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin:0 20px;
      cursor: pointer;
      .dialog-option-img{
        width: 53px;
        height: 60px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .dialog-option-name{
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        margin-top: 20px;
      }
      .dialog-option-name-bar{
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999FF;
        line-height: 21px;
      }
    }
    .dialog-option-item:hover{
      box-shadow: 0px 0px 9px 0px rgba(4, 0, 0, 0.06);
      border-radius: 7px;
    }
  }
}

@media screen and (max-width: 1440px) {
  .dialog-content{
    transform: scale(0.745);
  }
}
</style>