import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

const protocol = document.location.protocol
const domain = document.domain
if (protocol == 'http:' && process.env.NODE_ENV == 'production' && domain != 'wwwsdk.xiaozhuokeji.com') window.location.href = 'https:www.xinliangxiang.com'

Vue.config.productionTip = false
import 'normalize.css'
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';

Vue.use(VueFullPage)
.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
