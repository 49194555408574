import request from './request'

// 登录
export function login(data) {
  return request({
    url: 'v1/user/login',
    method: 'post',
    data
  })
}

// 退出登录
export function logout() {
  return request({
    url: 'v1/user/sign-out',
    method: 'post'
  })
}

// 注册
export function register(data) {
  return request({
    url: 'v1/user/register',
    method: 'post',
    data
  })
}
// 验证邮箱
export function verifyEmail(email) {
  return request({
    url: 'v1/account/send-verify-email',
    method: 'get',
    params: { email }
  })
}

// 获取验证码
export function getCode(mobile) {
  return request({
    url: 'v1/user/get-sms-code',
    method: 'get',
    params: { mobile }
  })
}
// 账户信息
export function mediaInfo(needLog = 1) {
  return request({
    url: 'v1/account/media-info',
    method: 'get',
    params: { needLog }
  })
}
// 修改账户信息
export function modifyAccount(data) {
  return request({
    url: 'v1/account/modify-account',
    method: 'post',
    data
  })
}


// app信息相关
export function fbxqAppInfo() {
  return request({
    url: 'v1/fenbeixinqiu/app/base-info',
    method: 'get'
  })
}


export function getVoiceCode(mobile) {
  return request({
    url: 'v1/user/get-sms-code',
    method: 'get',
    params: { mobile }
  })
}