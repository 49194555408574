<template>
  <div class="menu">
    <img
      src="../img/logo-top-new.png"
      alt=""
      @click="goHome"
      v-if="!isLast&&!fbxqLogShow"
      class="logo"
    >
    <img
      src="../img/fbxqLog.png" 
      alt=""
      @click="goHome"
      v-if="!isLast&&fbxqLogShow"
      class="logoFbxq" 
    >
    <div
      class="header-left"
      v-if="!isLast"
    >
      <!-- <div
        @click="goMenu(4)"
        :class="{active: isActive('fbxq')}"
      >
        分贝赚钱
      </div> -->
      <div
        @click="goMenu(4)"
        :class="{active: isActive('yyhb')}"
      >
        语音红包
      </div>
     
      <div
        @click="goMenu(1)"
        :class="{active: isActive('effect')}"
      >
        广告效果
      </div>
      <div
        @click="goMenu(2)"
        class="tag-div"
        :class="{active: isActive('flow')}"
      >
        媒体收益
        <!-- <div class="active-tag">
          eCPM保价100元
        </div> -->
      </div>
      <!-- <div v-if="$store.state.userInfo.isShowYuwan"
        @click="goMenu(5)"
        :class="{active: isActive('advert')}"
      >
        鱼玩盒子
      </div> -->
      <div
        @click="goMenu(3)"
        :class="{active: isActive('about')}"
      >
        关于我们
      </div>
    </div>
    <div
      class="header-right"
      v-if="!isLast"
    >
      <div
        v-if="!loginStatus"
        @click="handleClickLogin"
        class="header-login"
      >
        注册 / 登录
      </div>
      <div
        v-else
        class="login-info"
      >
        <div
          @click="handleClickAdmin"
          class="login-info-name"
        >
          {{ mainInfo.nameText }}>>
        </div>
        <div class="login-info-sign">
          <span @click="handleClickAdmin">管理后台</span> ｜ <span @click="handleClickLogout">退出</span>
        </div>
      </div>
    </div>
    <Dialog
      v-if="showDialog"
      @handleClickClose="handleClickClose"
      @loginSuccess="loginSuccess"
      @regSuccess="regSuccess"
    />
  </div>
</template>

<script>
  import { Message } from 'element-ui'
  import { mediaInfo, logout } from '@/api'
  import Dialog from './dialog'
  import { mapState } from 'vuex'
  import { hrefUrl ,hrefUrlVoice} from '@/api/env' 
  export default {
    props: ['isLast','fbxqLogShow'],
    data () {
      return {
        loginStatus: null,
        mainInfo: null,
        formLogin: null
      }
    },
    computed: {
      ...mapState(['showDialog'])
    },
    components: {
      Dialog
    },
    watch: {
      'isLast'() {
        console.log(this.isLast)
      }
    },
    methods: {
      goHome() {
        this.$router.push('/')
      },
      isActive(name) {
        let res
        this.$route.name==name?res = true: res = false
        return res
      },
      goMenu(index) {
        let name = ''
        switch (index) {
          case 1:
            name = 'effect'
            break;
          case 2:
            name = 'flow'
            break;
          case 3:
            name = 'about'
            break;
          case 4:
            name = 'yyhb'
          break;
          case 5:
            name = 'yuwan'
          break;
          default:
            break;
        }
        this.$router.push(name)
      },
      handleClickLogin() {
        this.$store.commit('changeDialog', true)
      },
      handleClickClose () {
        this.$store.commit('changeDialog', false)
      },
      async getMediaInfo() {
        const res = await mediaInfo()
        if (res.code === 200) {
          this.loginStatus = true
          this.mainInfo = res.data
          this.$store.commit("setUserInfo", res.data)
        } else {
          this.loginStatus = false
        }
      },
      loginSuccess(e) {
        this.getMediaInfo()
        this.$store.commit('changeDialog', false)
        this.locationMedia(e)
      },
      regSuccess(e) {
        this.getMediaInfo()
        this.$store.commit('changeDialog', false)
        this.locationMedia(e)
      },
      locationMedia(e) {
        if(e===2){
          window.location.href = hrefUrlVoice
        }else if(e===3){
          location.reload();
        } else{
          window.location.href = hrefUrl
        }
      },
      handleClickAdmin() {
        this.locationMedia()
      },
      async handleClickLogout() {
        const res = await logout()
        if (res.code === 200) {
          Message({
            message: '退出成功！',
            type: 'success',
            duration: 5 * 1000
          })
          this.$store.commit("setUserInfo", {})
          this.loginStatus = false
        } else {
          Message({
            message: res.msg,
            type: 'error',
            duration: 5 * 1000
          })
        }
      }
    },
    created () {
      this.getMediaInfo()
    }
  }
</script>

<style lang="scss" scoped>
.menu{
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  padding-top: 40px;
}
.logo{
  margin-left: 78px;
  width: 173px;
  height: 50px;
  cursor: pointer;
}
.header-left{
  display: flex;
  font-size: 26px;
  // font-family: 'fzqk';
  line-height: 26px;
  color: #fff;
  padding-top: 8px;
  div{
    position: relative;
    cursor: pointer;
    color: #fff;
    margin-left: 100px;
    // font-family: 'fzqk';
    &.active{
      color: rgba(255, 132, 0, 1);
      &:after{
        display: block;
        content: '';
        position: absolute;
        top: 41px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width:30px;
        height:4px;
        background:linear-gradient(45deg,rgba(255,96,0,1),rgba(255,144,0,1));
      }
    }
  }
}
.header-right {
  margin-right: 60px;
  margin-left: auto;
  display: flex;
  font-size: 26px;
  // font-family: 'fzqk';
  color: #fff;
  .header-login {
    width: 200px;
    height: 99px;
    cursor: pointer;
    line-height: 66px;
    background-image: url("../img/btn.png");
    text-align: center;
    background-size: 100% 100%;
    font-family: 'fzqk';
  }
}
.login-info{
  margin-top: 20px;
  cursor: pointer;
  &-name{
    font-size:20px;
    line-height: 20px;
    margin-bottom: 10px;
    color:rgba(255,255,255,1);
  }
  &-sign{
    font-size:16px;
    line-height: 16px;
    // font-family:'fzqk';
    font-weight:400;
    color:rgba(255,132,0,1);
  }
  .logoFbxq{
  width: 40px;
  height: 40px;
  }
}
@media screen and (max-width: 1440px) {
  .menu{
    padding-top: 30px;
  }
  .logo{
    width: 128px;
    height: 36px;
    margin-left: 60px;
  }
  .logoFbxq{
    width: 40px;
    height: 40px;
  }
  .header-left{
    font-size: 20px;
    line-height: 20px;
    padding-top: 6px;
    div{
      margin-left: 74px;
      &.active{
        &:after{
          top: 32px;
          width:23px;
          height:3px;
        }
      }
    }
  }
  .header-right {
    margin-right: 40px;
    font-size: 20px;
    .header-about{
      margin-right: 55px;
      margin-top: 14px;
      &.active{
        &:after{
          top: 32px;
          width:23px;
          height:3px;
        }
      }
    }
    .header-login{
      width: 160px;
      height: 81px;
      line-height: 50px;
    }
  }
  .login-info{
    margin-top: 14px;
    &-name{
      font-size:15px;
      line-height: 15px;
      margin-bottom: 8px;
    }
    &-sign{
      font-size:12px;
      line-height: 12px;
    }
  }
  // .active-tag{
  //   left: -22px!important;
  //   top: 48%!important;
  // }
}
 .logoFbxq{
  width: 60px;
  height: 60px;
  }
  .tag-div{
    position: relative;
    top: 0;
    left: 0;
    .active-tag{
      position: absolute;
      // top:45%;
      // left: -10px;
      margin-top: 5px;
      width: 133px;
      height: 34px;
      background-image: url("../img/active-tag.png");
      background-size: cover;
      background-repeat: no-repeat;
      font-size: 9px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 40px;
      text-indent: 31px;
      margin-left: 0;
      animation: mymove 1s ease infinite;
    }
  }
  @keyframes mymove {
  0% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  100% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
}
</style>