import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/advert',
    name: 'advert',
    component: () => import(/* webpackChunkName: "advert" */ '../views/Advert.vue')
  },
  {
    path: '/flow',
    name: 'flow',
    component: () => import(/* webpackChunkName: "flow" */ '../views/Flow.vue')
  },
  {
    path: '/rule',
    name: 'rule',
    component: () => import(/* webpackChunkName: "rule" */ '../views/Rule.vue')
  },
  {
    path:'/fbxq',
    name:'fbxq',
    component:()=>import(/* webpackChunkName: fbxq" */ '../views/Fbxq.vue')
  },
  {
    path:'/yyhb',
    name:'yyhb',
    component:()=>import(/* webpackChunkName: "yyhb" */ '../views/Yyhb.vue')
  },
  {
    path:'/yuwan',
    name:'yuwan',
    component:()=>import(/* webpackChunkName: "yuwan" */ '../views/Yuwan.vue')
  },
  {
    path:'/yyhbOld',
    name:'yyhbOld',
    component:()=>import(/* webpackChunkName: "yyhbOld" */ '../views/YyhbOld.vue')
  },
  {
    path:'/effect',
    name:'effect',
    component:()=>import(/* webpackChunkName: "effect" */ '../views/Effect.vue')
  },
  {
    path:'/flowOld',
    name:'FlowOld',
    component:()=>import(/* webpackChunkName: "FlowOld" */ '../views/FlowOld.vue')
  }
]

const router = new VueRouter({
  routes,
  mode:'history'
})

export default router
