import axios from 'axios'
import { Message } from 'element-ui'
import Qs from 'qs'
import { baseUrlVoice } from './env'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
const service = axios.create({
  withCredentials: true,
  timeout: 5000 // request timeout
})
service.defaults.baseURL = baseUrlVoice
// request interceptor
service.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      config.data = config.data instanceof FormData ? config.data : Qs.stringify(config.data)
    }
    if (config.url === 'v1/upload/cloud') {
      config.headers['Content-Type'] = 'multipart/form-data'
    }

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    return res
  },
  error => {
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
