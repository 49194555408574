<template>
  <div class="home">
    <full-page
      ref="fullpage"
      :options="options"
    >
      <div class="section">
        <div class="header">
          <!-- <img
            class="header-bg"
            src="../img/home-header.png"
            alt=""
          > -->
          <div class="header-content">
            <div class="header-text">
              <div class="header-title">
                <img
                  src="../img/title.png"
                  alt=""
                >
                <h1 class="header-title-desc">
                  高收益<span>流量价值</span>探索者
                </h1>
              </div>
              <h3 class="header-sign">
                - 注重细节创新，追求极致的用户转化 -
              </h3>
            </div>
            <div
              class="header-sb"
              @click="handleClickScrollNext"
            >
              <img
                src="../img/sb.png"
                alt=""
              >
            </div>
            <!-- <div class="header-playbtn">
              暂停
            </div> -->
          </div>
        </div>
      </div>


      <!-- 老版本官网 -->
      <!-- <div class="section section2">
        <div class="section-title">
          丰富的广告资源，覆盖85%移动互联网用户
        </div>
        <div class="section-desc">
          优化、整合营销以及广告数据洞察等专业服务，满足游戏、电商、网服、教育、美妆等行业客户的推广需求。通过新量象的全球化移动广告平台，您可轻松将您的营销信息传递到目标人群！
        </div>
        <div class="section2-map">
          <div class="map-name">
            杭州
          </div>
          <div class="map-list">
            <div
              class="map-item"
              :class="`map-item${index+1}`"
              v-for="(item,index) in 30"
              :key="index"
            >
              <div class="dot" />
              <div class="inner-circle" />
              <div class="outer-circle" />
            </div>
          </div>
          <img
            class="section2-map-img1"
            src="../img/map1.png"
            alt=""
          >
          <img
            class="section2-map-img2"
            src="../img/map2.png"
            alt=""
          >
          <div class="section2-map-sign">
            <div class="section2-map-icon">
              <div />
            </div>
            <div class="section2-map-name">
              已覆盖区域
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="midder">
          <div class="section-title">
            我们的服务
          </div>
          <div class="section-desc">
            新量象团队一直以来秉承“创新进取、追求卓越”的信念和“让城市更美好、让生活更美好、让创业更美好、让社会更美好”的企业精神，为合作伙伴创造出无限价值，实现双方共赢。
          </div>
          <div class="midder-content">
            <div class="midder-list">
              <div
                class="midder-item"
                v-for="(item, index) in list"
                :key="index"
              > 
                <img
                  class="midder-item-line"
                  src="../img/icon.png"
                  alt=""
                >
                <img
                  src="../img/box-bg.png"
                  alt=""
                  class="midder-item-bg"
                >
                <div class="midder-item-icon1">
                  <img
                    src="../img/box-icon2.png"
                    alt=""
                  >
                  <img
                    :src="item.icon"
                    alt=""
                  >
                </div>
                <div
                  class="midder-item-icon"
                  @click="goDetail(index)"
                >
                  <img
                    src="../img/box-icon1.png"
                    alt=""
                  >
                  <img
                    :src="item.icon1"
                    alt=""
                  >
                </div>
                <div class="midder-item-name">
                  {{ item.name }}
                </div>
                <div class="midder-item-desc">
                  {{ item.desc2 }}
                </div>
                <div
                  v-html="item.desc1"
                  class="midder-item-desc1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section4">
        <div class="section-title">
          成功案例
        </div>
        <div class="section-desc">
          新量象平台业务覆盖广，始终致力于以流量为绳索，在垂直广告领域与用户精准领域不断探索，开发新形式产品，是高收益流量价值的探索者，立志成为国内效果广告行业里细节创新、用户转化方面的佼佼者。
        </div>
        <div class="swiper">
          <img
            src="../img/swiper-left.png"
            alt=""
            class="swiper-left"
            @click="handleClickSwiperLeft"
          >
          <swiper
            ref="mySwiper"
            class="swiper-box"
            :options="swiperOption"
          >
            <swiper-slide
              v-for="(item, index) in swiperList"
              data-index="index"
              :key="index"
              class="swiper-item"
            >
              <div class="swiper-img-box">
                <img
                  class="swiper-img"
                  :src="item.pic"
                >
              </div>
              <div class="swiper-text-box">
                <div class="swiper-title">
                  {{ item.name }}
                </div>
                <div class="swiper-line" />
                <div class="swiper-desc">
                  {{ item.desc }}
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <img
            src="../img/swiper-right.png"
            alt=""
            class="swiper-right"
            @click="handleClickSwiperRight"
          >
        </div>
      </div>
      <div class="section section5">
        <div class="section-title">
          新量象合作伙伴
        </div>
        <div class="section-desc">
          新量象控股全球合作伙伴已超过上万家，在多个领域都取得不错的成绩。我们用专注的态度为您提供专业的服务，能够满足客户的多方位需求，是您企业发展之路的重要伙伴。
        </div>
        <img
          src="../img/section5-img.png"
          alt=""
          class="section5-img"
        >
      </div> -->
      <div class="section section6">
        <div class="sectionWarp">
          <div class="section-title">
            旗下产品
          </div>
          <div class="section-desc">
            无创新，不产品；无体验，不产品；一切皆为提升流量价值！
          </div>
          <div class="section-list">
            <div
              class="section-item-warp"
              @mouseenter="productShow=true"
              @mouseleave="productShow=false"
            >
              <transition name="fade">
                <div
                  class="section-item"
                  v-if="!productShow"
                >
                  <div class="section-item-header">
                    <img
                      src="../img/yyhb-logo.png"
                      alt=""
                    >
                  </div>
                  <div class="section-item-title">
                    语音广告
                  </div>
                  <div class="section-item-bar">
                    用户可以参与口读的新广告形式
                  </div>
                  <div class="section-item-line">
                    <img
                      src="../img/line.png"
                      alt=""
                    >
                  </div>
                  <div class="section-item-text">
                    用户对推广产品的核心价值感知更深，转化才会越高，流量价值即越大<br>而正好：<br>口读文案的感知＞看视频＞看图片
                  </div>
                  <!-- <div
                    v-show="productShow"
                    @click="goRouter('yyhb')"
                    class="section-item-btn"
                  >
                    查看详情
                  </div> -->
                </div>
              </transition>
              <transition name="fade">
                <div
                  v-if="productShow"
                  class="section-item section-item-big"
                >
                  <div class="section-item-header">
                    <img
                      src="../img/yyhb-logo.png"
                      alt=""
                    >
                  </div>
                  <div class="section-item-title">
                    语音广告
                  </div>
                  <div class="section-item-bar">
                    用户可以参与口读的新广告形式
                  </div>
                  <div class="section-item-line">
                    <img
                      src="../img/line.png"
                      alt=""
                    >
                  </div>
                  <div class="section-item-text">
                    用户对推广产品的核心价值感知更深，转化才会越高，流量价值即越大<br>而正好：<br>口读文案的感知＞看视频＞看图片
                  </div>
                  <div
                    v-show="productShow"
                    @click="goRouter('yyhb')"
                    class="section-item-btn"
                  >
                    查看详情
                  </div>
                </div>
              </transition>
            </div>
          
            <div
              class="section-item-warp"
              @mouseenter="productShow2=true"
              @mouseleave="productShow2=false"
            >
              <transition name="fade">
                <div
                  class="section-item"
                  v-if="!productShow2"
                >
                  <div class="section-item-header">
                    <img
                      src="../img/ywhz.png"
                      alt=""
                    >
                  </div>
                  <div class="section-item-title">
                    鱼玩盒子
                  </div>
                  <div class="section-item-bar">
                    玩优质游戏，领体验奖励
                  </div>
                  <div class="section-item-line">
                    <img
                      src="../img/line.png"
                      alt=""
                    >
                  </div>
                  <div class="section-item-text">
                    合理的奖励必将激发更多的用户参与游戏，优质的游戏产品必将深度转化参与的用户，高品质优体验必将受到更多用户青睐
                  </div>
                  <!-- <div
                v-show="productShow2"
                class="section-item-btn"
              >
                查看详情
              </div> -->
                </div>
              </transition>
              <transition name="fade">
                <div
                  class="section-item section-item-big"
                  v-if="productShow2"
                >
                  <div class="section-item-header">
                    <img
                      src="../img/ywhz.png"
                      alt=""
                    >
                  </div>
                  <div class="section-item-title">
                    鱼玩盒子
                  </div>
                  <div class="section-item-bar">
                    玩优质游戏，领体验奖励
                  </div>
                  <div class="section-item-line">
                    <img
                      src="../img/line.png"
                      alt=""
                    >
                  </div>
                  <div class="section-item-text">
                    合理的奖励必将激发更多的用户参与游戏，优质的游戏产品必将深度转化参与的用户，高品质优体验必将受到更多用户青睐
                  </div>
                  <!-- <div v-if="$store.state.userInfo.isShowYuwan"
                    class="section-item-btn"
                    @click="goRouter('yuwan')"
                  >
                    查看详情
                  </div> -->
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div class="section section7">
        <div class="section-voice">
          <div class="section-voice-left">
            <div class="section-voice-title">
              语音红包
            </div>
            <div class="section-voice-bar">
              口读互动，加强感知，提升广告效果，提高媒体收益
            </div>
            <div class="section-voice-slogn">
              “ 读出来那一刻，我强烈的get到了 推广产品的核心价值 ”
            </div>
            <div class="section-voice-slogn-name">
              <!-- ——九剑 -->
            </div>
            <div
              class="section-voice-slogn-btn"
              @click="goRouter('yyhb')"
            >
              点我了解语音红包
            </div>
          </div>
          <div class="section-voice-right">
            <div class="section-voice-phone-title">
              {{ videoPhoneTitle }}
            </div>
            <div class="section-voice-phone">
              <img
                src="../img/section-voice-phone.png"
                alt=""
              >
            </div>
            <div class="section-voice-phone-mp4">
              <video
                src="https://voicefile.xinliangxiang.com/video/mp4.mp4"
                autoplay="autoplay"
                loop="loop"
                id="video"
                :muted="muted"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="section section8">
        <div class="advert-effect">
          <div class="advert-effect-title">
            广告效果
          </div>
          <div class="advert-effect-bar">
            让每一个产品的核心价值都能触动人心！
          </div>
          <div class="advert-effect-content">
            <div class="advert-effect-start">
              <div class="advert-effect-header">
                <img
                  src="../img/yyhb-logo.png"
                  alt=""
                >
              </div>
              <div class="advert-effect-text">
                用户口读推广产品
                设置的口令文案
              </div>
            </div>
            <div class="advert-effect-bottombg">
              <div class="advert-effect-bottom-list">
                <div class="advert-effect-bottom-item">
                  <div
                    class="advert-effect-item-title-warp"
                    :class="effectActive?'advert-effect-item-title-warp-active':''"
                  >
                    <div class="advert-effect-item-title">
                      <div class="advert-effect-item-title-top">
                        <img
                          src="../img/advert-effect-item.png"
                          alt=""
                        >
                      </div>
                      <div class="advert-effect-item-title-bottom">
                        曝光转化更高
                      </div>
                    </div>
                    <div class="advert-effect-item-title advert-effect-item-active">
                      <div class="advert-effect-item-title-top">
                        曝光转化更高
                      </div>
                      <div class="advert-effect-item-title-bottom">
                        因为主动读出来，<br>
                        感知更强
                      </div>
                    </div>
                  </div>
                  <div class="advert-effect-item-line" />
                  <div class="advert-effect-item-round">
                    <img
                      src="../img/advert-effect-item-round.png"
                      alt=""
                    >
                  </div>
                </div>
                <div class="advert-effect-bottom-item  advert-effect-item-one">
                  <div
                    class="advert-effect-item-title-warp"
                    :class="effectActiveOne?'advert-effect-item-title-warp-active':''"
                  >
                    <div class="advert-effect-item-title">
                      <div class="advert-effect-item-title-top">
                        <img
                          src="../img/advert-effect-item-one.png"
                          alt=""
                        >
                      </div>
                      <div class="advert-effect-item-title-bottom">
                        精准定向
                      </div>
                    </div>
                    <div class="advert-effect-item-title advert-effect-item-active">
                      <div class="advert-effect-item-title-top">
                        精准定向
                      </div>
                      <div class="advert-effect-item-title-bottom">
                        声音可准确识别性别 、<br>
                        年龄范围等属性
                      </div>
                    </div>
                  </div>
                  <div class="advert-effect-item-line" />
                  <div class="advert-effect-item-round">
                    <img
                      src="../img/advert-effect-item-round.png"
                      alt=""
                    >
                  </div>
                </div>
                <div class="advert-effect-bottom-item  advert-effect-item-two">
                  <div
                    class="advert-effect-item-title-warp"
                    :class="effectActiveTwo?'advert-effect-item-title-warp-active':''"
                  >
                    <div class="advert-effect-item-title">
                      <div class="advert-effect-item-title-top">
                        <img
                          src="../img/advert-effect-item-two.png"
                          alt=""
                        >
                      </div>
                      <div class="advert-effect-item-title-bottom">
                        合理成本
                      </div>
                    </div>
                  
                    <div class="advert-effect-item-title advert-effect-item-active">
                      <div class="advert-effect-item-title-top">
                        合理成本
                      </div>
                      <div class="advert-effect-item-title-bottom">
                        平台采用oCPR(即按获客成本)<br>
                        出价，且有保价赔付机制
                      </div>
                    </div>
                  </div>
                  <div class="advert-effect-item-line" />
                  <div class="advert-effect-item-round">
                    <img
                      src="../img/advert-effect-item-round.png"
                      alt=""
                    >
                  </div>
                </div>
                <div class="advert-effect-bottom-item  advert-effect-item-three">
                  <div
                    class="advert-effect-item-title-warp"
                    :class="effectActiveThree?'advert-effect-item-title-warp-active':''"
                  >
                    <div class="advert-effect-item-title">
                      <div class="advert-effect-item-title-top">
                        <img
                          src="../img/advert-effect-item-three.png"
                          alt=""
                        >
                      </div>
                      <div class="advert-effect-item-title-bottom">
                        100%真实流量
                      </div>
                    </div>
                    <div class="advert-effect-item-title advert-effect-item-active">
                      <div class="advert-effect-item-title-top">
                        100%真实流量
                      </div>
                      <div class="advert-effect-item-title-bottom">
                        每一次口读都通过声纹，<br>
                        进行活体检测
                      </div>
                    </div>
                  </div>
                  <div class="advert-effect-item-line" />
                  <div class="advert-effect-item-round">
                    <img
                      src="../img/advert-effect-item-round.png"
                      alt=""
                    >
                  </div>
                </div>
                <div class="advert-effect-bottom-item  advert-effect-item-four">
                  <div
                    class="advert-effect-item-title-warp"
                    :class="effectActiveFour?'advert-effect-item-title-warp-active':''"
                  >
                    <div class="advert-effect-item-title">
                      <div class="advert-effect-item-title-top">
                        <img
                          src="../img/advert-effect-item-four.png"
                          alt=""
                        >
                      </div>
                      <div class="advert-effect-item-title-bottom">
                        灵活的素材配置
                      </div>
                    </div>
                    <div class="advert-effect-item-title advert-effect-item-active">
                      <div class="advert-effect-item-title-top">
                        灵活的素材配置
                      </div>
                      <div class="advert-effect-item-title-bottom">
                        详情介绍支持电视广告播音+<br>
                        图片展示、及视频内容展示等
                      </div>
                    </div>
                  </div>
                  <div class="advert-effect-item-line" />
                  <div class="advert-effect-item-round">
                    <img
                      src="../img/advert-effect-item-round.png"
                      alt=""
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="advert-effect-btn"
            @click="goRouter('effect')"
          >
            了解详情
          </div>
        </div>
      </div>
      <div class="section section9">
        <div class="section-profit">
          <div class="profit-title">
            媒体收益
          </div>
          <div class="profit-bar">
            新广告，新收益，流量价值最大化
            <!-- ，保底100元/eCPM -->
          </div>
          <div class="profit-content">
            <div class="profit-left">
              <div class="profit-content-list">
                <div class="profit-content-item">
                  <div class="profit-content-item-header">
                    <img
                      src="../img/profit-content-item-header-one.png"
                      alt=""
                    >
                  </div>
                  <div class="profit-content-item-text">
                    增加新的入口，多一份新的流量收益；
                  </div>
                </div>
                <div class="profit-content-item">
                  <div class="profit-content-item-header">
                    <img
                      src="../img/profit-content-item-header-two.png"
                      alt=""
                    >
                  </div>
                  <div class="profit-content-item-text">
                    与激励视频结合，将部分老用户切换到
                    语音红包，优化整体收益；
                  </div>
                </div>
                <div class="profit-content-item">
                  <div class="profit-content-item-header">
                    <img
                      src="../img/profit-content-item-header-three.png"
                      alt=""
                    >
                  </div>
                  <div class="profit-content-item-text">
                    无需强制观看30s+，解决用户等待时间
                    较长的体验问题。
                  </div>
                </div>
              </div>
              <div class="profit-bottom">
                <!-- <div class="profit-bottom-title">
                  新·创计划 — 保底100元/eCPM活动邀您参与
                </div> -->
                <div
                  class="profit-bottom-btn"
                  @click="goRouter('flow')"
                >
                  查看更多
                </div>
              </div>
            </div>
            <div class="profit-right">
              <div class="profit-right-comtent">
                <div class="profit-right-comtent-bg">
                  <img
                    src="../img/profit-right-comtent-smallimg.png"
                    alt=""
                  >
                </div>
                <div class="profit-right-comtent-bg profit-right-comtent-sml">
                  <img
                    src="../img/profit-right-bg.png"
                    alt=""
                  >
                </div>
                <div class="profit-right-comtent-bg profit-right-comtent-within">
                  <img
                    src="../img/profit-right-comtent-bg.png"
                    alt=""
                  >
                </div>
                <div class="profit-right-comtent-img">
                  <img
                    src="../img/profit-right-comtent-img.png"
                    alt=""
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section10">
        <div class="demonstration">
          <div class="demonstration-title">
            语音互动广告接入场景展示
          </div>
          <div class="demonstration-path">
            <div class="demonstration-path-item">
              <div class="demonstration-path-item-header">
                <img
                  src="../img/demonstration-path-item-header-one.png"
                  alt=""
                >
              </div>
              <div class="demonstration-path-item-text">
                选择入口 设置奖励
              </div>
              <div class="demonstration-path-item-line">
                <img
                  src="../img/demonstration-path-item-line.png"
                  alt=""
                >
              </div>
            </div>
            <div class="demonstration-path-item">
              <div class="demonstration-path-item-header">
                <img
                  src="../img/demonstration-path-item-header-two.png"
                  alt=""
                >
              </div>
              <div class="demonstration-path-item-text">
                用户读口令 拆语音红包
              </div>
              <div class="demonstration-path-item-line">
                <img
                  src="../img/demonstration-path-item-line.png"
                  alt=""
                >
              </div>
            </div>
            <div class="demonstration-path-item">
              <div class="demonstration-path-item-header">
                <img
                  src="../img/demonstration-path-item-header-three.png"
                  alt=""
                >
              </div>
              <div class="demonstration-path-item-text">
                获得奖励
                点击广告
              </div>
            </div>
          </div>
          <div class="demonstration-content">
            <div class="demonstration-content-list">
              <div
                class="demonstration-content-item"
                v-for="(item,index) in demonstrationDataLeft"
                :key="index"
                @mouseenter="demonstrationLive(index)"
              >
                <div class="demonstration-content-item-img">
                  <img
                    v-if="demonstrationIndex===index"
                    :src="item.picActive"
                    alt=""
                  >
                  <img
                    v-else
                    :src="item.pic"
                    alt=""
                  >
                </div>
                <div
                  class="demonstration-content-item-text"
                  :class="demonstrationIndex===index?'fontColor':''"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div class="demonstration-content-phone">
              <div class="picPhoneWarp">
                <img
                  src="../img/picPhoneWarp.png"
                  alt=""
                >
              </div>
              <div
                class="carouselDiv"
                v-for="(item,index) in demonstrationData"
                :key="index"
              >
                <transition name="fade">
                  <img
                    :src="item.picPhone"
                    alt=""
                    v-show="demonstrationIndex===index"
                  >
                </transition>
              </div>
              <!-- <el-carousel
                height="471px"
                :autoplay="false"
              >
                <el-carousel-item
                  v-for="(item,index) in demonstrationData"
                  :key="index"
                > 
                  <img
                    :src="item.picPhone"
                    alt=""
                  >
                </el-carousel-item>
              </el-carousel> -->
            </div>
            <div class="demonstration-content-list">
              <div
                class="demonstration-content-item"
                v-for="(item,index) in demonstrationDataRight"
                :key="index"
                @mouseenter="demonstrationLive(index+4)"
              >
                <div class="demonstration-content-item-img">
                  <img
                    v-if="demonstrationIndex===index+4"
                    :src="item.picActive"
                    alt=""
                  >
                  <img
                    v-else
                    :src="item.pic"
                    alt=""
                  >
                </div>
                <div
                  class="demonstration-content-item-text"
                  :class="demonstrationIndex===index+4?'fontColor':''"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            class="demonstration-btn"
          >
            下载DEMO演示
          </div> -->
        </div>
      </div>
      <div class="section">
        <div class="reg-box">
          <div class="reg-title">
            现在注册 立即体验新量象服务
          </div>
          <div
            class="reg-btn"
            @click="handleClickDialog"
          >
            注册 / 登录
          </div>
        </div>
    
        <div class="footer">
          <div class="footer-content">
            <div class="footer-contact">
              <Contact />
            </div>
            <img
              src="../img/footer-line.png"
              alt=""
              class="footer-line"
            >
            <div class="footer-info">
              <div class="footer-logo">
                <img
                  src="../img/logo-bottom.png"
                  alt=""
                >
              </div>
              <div class="footer-info-list">
                <div class="footer-info-item">
                  <div>公司名称</div>
                  <div>杭州新量象网络科技有限公司</div>
                </div>
                <!-- <div class="footer-info-item">
                  <div>公司注册地址</div>
                  <div>
                    浙江省丽水市莲都区城北街368号绿谷信息产业园1号楼2楼201-1
                  </div>
                </div> -->
                <div class="footer-info-item">
                  <div>公司地址</div>
                  <div>
                    浙江省杭州市余杭区未来科技城五常街道联创街
                    153号丽水数字大厦B幢9F
                  </div>
                </div>
                <div class="footer-info-item">
                  <div>商务电联</div>
                  <div>0571-89808775</div>
                </div>
              </div>
            </div>

            <div class="footer-sign">
              <div class="footer-sign-line" />
              <div class="footer-sign-desc">
                © 2019  杭州新量象网络科技有限公司  版权所有 |  <a
                  href="https://beian.miit.gov.cn"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-if="beian"
                >浙ICP备19045824号-1</a>
                <a
                  href="https://beian.miit.gov.cn"
                  target="_blank"
                  rel="noopener noreferrer"
                  v-else
                >浙ICP备2021034612号</a>
              </div>
              <div class="footer-sign-line" />
            </div>
          </div>
        </div>
      </div>
    </full-page>
    <Header :is-last="isLast" />
    <el-button class="warning-btn" type="warning" @click="showWarningAlert=true" icon="el-icon-warning-outline">防诈骗提示</el-button>
    <el-dialog :visible.sync="showWarningAlert" class="warning-alert" :show-close=false :close-on-click-modal=false width="70%" top="0" @close="warningAlertClose">
      <h1>致广大用户的一封信</h1>
      <h2>亲爱的用户们：</h2>
      <br/>
      近日，我司接到用户反馈，有不法分子假冒我司及工作人员名义，以网络刷单等方式骗取钱财。
      <br/>
      <br/>
      <h2>在此，我司提醒广大用户注意：</h2>
      一、我司从未开发或运营“新量象”APP，任何人要求下载前述APP的，均为诈骗。
      <br/>
      二、我司主营业务为广告推广，合作对象为企业客户。若有以我司名义添加您QQ、微信，并要求您转账、刷单的，均为诈骗。
      <br/>
      请广大用户保持警惕，保护好个人身份证、手机号码、银行账号等关键信息，请勿向他人泄露。
      <br/>
      <br/>
      若您因类似诈骗事件遭受损失，我司建议您及时向您所在地公安报警，并可同我司（联系电话：0571-8477585）核实信息。我司将积极配合相关部门的调查，协助您维护正当权益，并保留追究涉嫌违法人员法律责任的一切权利。
      <div class="logo"><img src="@/img/home-warning-alert-logo.png" alt=" "></div> 
      <p>杭州新量象网络科技有限公司</p>
      <p>2022年4月18日</p>
      <div class="close-btn" @click="showWarningAlert=false"></div>
    </el-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/header'
import Contact from '@/components/Contact'
import { mapState } from 'vuex'
// import { swiper, swiperSlide } from "vue-awesome-swiper";
require("swiper/dist/css/swiper.css");

export default {
  name: "Home",
  components: {
    Header,
    // swiper,
    // swiperSlide,
    Contact
  },
  computed: {
    ...mapState(['showDialog']),
    swiper() {
      return this.$refs.mySwiper.swiper
    }
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        loop : true
      },
      swiperList: [
        {pic: require('../img/swiper1.png'), name: '魔急便', desc: '“很好的挖掘了用户的潜在价值，在维持原有的业务上，也为我们增加了一块业务收益，多元化的app内容也大大提高了用户粘性。”'},
        {pic: require('../img/swiper2.png'), name: '今日头条', desc: '“签到活动、积分用户运营，多元化的产品矩阵，持续不断的创新力，紧紧围绕用户运营的各个痛点逐一击破，签到产品次日留存数据达到80%以上，十分给力。”'},
        {pic: require('../img/swiper3.png'), name: 'PP视频', desc: '“我们和新量象联运开发的app分贝赚钱很好的解决了流量怎么变现的问题，我们无需付出任何技术及人工成本，每天后台就能看到收益的明显增加，很好的运作模式，非常看好。”'},
        {pic: require('../img/swiper4.png'), name: '麦游捕鱼', desc: '“和新量象合作已经一年多了，很好的帮助我们引导了一大批忠实用户，自身app知名度也得到了很高的提升，希望和新量象有更深度的合作。”'},
      ],
      activeDialog: false,
      options: {
        licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
        onLeave: this.onLeave,
        scrollOverflow: false,
        scrollBar: false,
        menu: '#menu',
      },
      isLast: false,
      list: [
        {
          name: '鱼玩盒子',
          desc1: '聚集100+cpl游戏 用户试玩体验即可获得奖励 一键推广，10秒搞定',
          desc2: '适用于APP媒体，可以SDK、H5等方式接入，单个用户arpu值可达12.5+',
          icon: require('../img/ywhz.png'),
          icon1: require('../img/ywhz.png'),
        },
        {
          name: '语音红包',
          desc1: '用声音传递广告价值，精准触达高价值目标受众',
          desc2: '适合任何流量媒体，对接快速，可进行流量高效变现',
          icon: require('../img/yyhb-logo.png'),
          icon1: require('../img/yyhb-logo.png'),
        },
        {
          name: '互动聚合',
          desc1: '适用于任何流量：APP、WAP、公众号等公众号流量。<br>合作方式：敬请期待',
          desc2: '实时推荐行业内最高收益的互动广告平台，超高的广告填充率，流量利用率最大化',
          icon: require('../img/media-icon.png'),
          icon1: require('../img/media-icon1.png'),
        },
        {
          name: '铃声订阅',
          desc1: '适用于任何流量：APP、WAP、公众号等公众号流量。<br>合作方式：敬请期待',
          desc2: '一次订购月月分成，超稳定长久的收益',
          icon: require('../img/media-icon.png'),
          icon1: require('../img/media-icon1.png'),
        }
      ],
      productShow:false ,//鼠标移入动画
      productShow2:false,
      effectActive:false, //广告效果翻牌动画
      effectActiveOne:false,
      effectActiveTwo:false,
      effectActiveThree:false,
      effectActiveFour:false,
      demonstrationIndex:0, //语音互动广告接入场景默认展示
      demonstrationData:[
        {
          pic:require('../img/demonstration1.png'),picActive:require('../img/demonstrationActiv1.png'),picPhone:require('../img/demonstrationPhone8.png'),name:'增值功能'
        },
        {
          pic:require('../img/demonstration2.png'),picActive:require('../img/demonstrationActiv2.png'),picPhone:require('../img/demonstrationPhone5.png'),name:'游戏关卡'
        },
        {
          pic:require('../img/demonstration3.png'),picActive:require('../img/demonstrationActiv3.png'),picPhone:require('../img/demonstrationPhone6.png'),name:'娱乐抽奖'
        },
        {
          pic:require('../img/demonstration4.png'),picActive:require('../img/demonstrationActiv4.png'),picPhone:require('../img/demonstrationPhone2.png'),name:'任务中心'
        },
        {
          pic:require('../img/demonstration5.png'),picActive:require('../img/demonstrationActiv5.png'),picPhone:require('../img/demonstrationPhone4.png'),name:'积分商城'
        },
        {
          pic:require('../img/demonstration6.png'),picActive:require('../img/demonstrationActiv6.png'),picPhone:require('../img/demonstrationPhone7.png'),name:'签到模块'
        },
        {
          pic:require('../img/demonstration7.png'),picActive:require('../img/demonstrationActiv7.png'),picPhone:require('../img/demonstrationPhone3.png'),name:'权益获取'
        },
        {
          pic:require('../img/demonstration8.png'),picActive:require('../img/demonstrationActiv8.png'),picPhone:require('../img/demonstrationPhone1.png'),name:'金币兑换'
        },
      ],//语音互动广告接入场景展示数据
      muted:true  ,//视频自动播放
      videoPhoneTitle:'合适场景用户获得语音红包',  //视频打点时间文案
      beian:true, //备案
      showWarningAlert: true, // 是否显示警告提示框
      warningAlertTimerId: 0, // 警告弹窗定时器ID
    };
  },
  created(){

    //语音互动广告接入场景展示数据处理
    this.demonstrationDataLeft = this.demonstrationData.slice(0,4)
    this.demonstrationDataRight = this.demonstrationData.slice(4)

    this.warningAlertTimerId = setTimeout(() => {
      this.showWarningAlert = false
    }, 5000);
  },
  mounted(){
    this.getDomain()
  },
  methods: {
    // 诈骗警告弹窗关闭回调
    warningAlertClose() {
      clearTimeout(this.warningAlertTimerId)
    },
    handleClickSwiperLeft() {
      this.swiper.slidePrev()
    },
    handleClickSwiperRight() {
      this.swiper.slideNext()
    },
    onLeave(fullpage, index) {
      this.isLast = index.isLast

      //视频播放打点
      if(index.index==2){
        this.videoProgressActive()
      }
      //广告效果栏动画开启
      if(index.index==3){
        this.advertEffect()
      }
    },
    handleClickScrollNext() {
      this.$refs.fullpage.api.moveSectionDown()
    },
    handleClickDialog() {
      if (!this.showDialog) {
        this.$store.commit('changeDialog', true)
      }
    },
    goDetail(index){
      if(index==1){
        this.$router.push('/fbxq')
      }
    },
    //语音互动广告接入场景展示事件
    demonstrationLive(index){
      this.demonstrationIndex = index
    },
    //广告效果栏动画开启
    advertEffect(){
     setTimeout(()=>{
        this.effectActive = true
      },2000)
      setTimeout(()=>{
        this.effectActiveOne = true
      },3000)
      setTimeout(()=>{
        this.effectActiveTwo = true
      },4000)
      setTimeout(()=>{
        this.effectActiveThree = true
      },5000)
      setTimeout(()=>{
        this.effectActiveFour = true
      },6000)
    },
    //视频播放动画
    videoProgressActive(){
      // this.muted =false
      var that =this
      var vid = document.getElementById("video"); 
      vid.play()
      setInterval(function () {
        var currentTime=vid.currentTime.toFixed(0);
        if(currentTime==0&&currentTime<3){
          that.videoPhoneTitle = '合适场景用户获得语音红包'
        }else if(currentTime>3&&currentTime<9){
          that.videoPhoneTitle = '进入语音红包'
        }else if(currentTime>9&&currentTime<17){
          that.videoPhoneTitle = '按住麦克风自主朗读口令'
        }else if(currentTime>19&&currentTime<29){
          that.videoPhoneTitle = '朗读成功动效依次展示'
        }else if(currentTime>29){
          that.videoPhoneTitle = '最后一步转化用户'
        }

      }, 1000);
      
    },
    //页面跳转
    goRouter(name){
      this.$router.push(name)
    },
    //备案更改
    getDomain(){
      let domain = document.domain;//先获取当前访问的全域名
      //let domain = 'http://www.xinliangxiang.cn';//先获取当前访问的全域名
      let domain2 = domain.substring(domain.indexOf('.')+1);//截取第一个点的位置来获取根域名,这样只能获取二级域名的根域名，如果直接是不带www的域名访问，那么就会直接获取域名的后缀
      if(domain2.indexOf('.')==-1){
          //可以通过判断来二次获取点的位置
          console.log(document.domain)
      }else{
        console.log(domain2)
        if(domain2=='xinliangxiang.cn'){
          this.beian=false
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
img{
  width: 100%;
  height: 100%;
}
.home{
  width: 100vw;
  position: relative;
}
.header {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url('http://imgs1.zhuoyixia.com/xinliangxiang/home-header.png');
  background-size: cover;
  .header-content {
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    position: absolute;
    z-index: 2;
    color: #fff;
    .header-text {
      position: absolute;
      top: 36.3%;
      left: 0;
      right: 0;
      text-align: center;
      margin: 0 auto;
    }
    .header-title {
      white-space: nowrap;
      position: relative;
      font-weight: bold;
      margin-bottom: 60px;
      &-desc {
        font-size: 120px;
        line-height: 120px;
        margin: 0;
        // font-family: 'fzqk';
        span {
          margin: 0 92px;
          // font-family: 'fzqk';
        }
      }
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 605px;
        height: 146px;
      }
    }
    .header-sign {
      font-size: 44px;
      line-height: 44px;
      text-align: center;
      margin: 0;
      // font-family: 'fzqk';
    }
    .header-sb {
      cursor: pointer;
      width: 54px;
      height: 110px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 40px;
      margin: 0 auto;
      animation: rightan 1.5s infinite;
      animation-fill-mode: both;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .header-playbtn{
      cursor: pointer;
      position: absolute;
      right: 40px;
      bottom: 40px;
      width: 90px;
      height: 34px;
      border: 1px solid #fff;
      border-radius: 17px;
      line-height: 34px;
      text-align: center;
    }
  }
}
.midder{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url('../img/home-bg.png');
  background-size: cover;
  .midder-bg {
    position: relative;
    width: 100%;
  }
  &-list{
    margin-top: 47px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-item:nth-child(4){
    margin-right: 0;
  }
  &-item{
    cursor: pointer;
    position: relative;
    margin-right: 50px;
    width: 345px;
    height: 507px;
    &-bg{
      width: 345px;
      height: 377px;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      opacity: 0;
      transition: all 0.5s;
    }
    &-line{
      width: 218px;
      height: 10px;
      bottom: 137px;
      left: 0;
      right: 0;
      margin: 0 auto;
      position: absolute;
      opacity: 0;
      transition: all 0.5s;
    }
    &-icon{
      position: absolute;
      width: 265px;
      height: 265px;
      top: 57px;
      left: 0;
      right: 0;
      margin: 0 auto;
      transition: all 0.5s ease;
      opacity: 1;
      img:nth-child(1){
        width: 100%;
        height: 100%;
      }
      img:nth-child(2){
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 140px;
        height: 140px;
        z-index: 2;
        border-radius: 50%;
      }
    }
    &-icon1{
      position: absolute;
      width: 227px;
      height: 227px;
      top: 57px;
      left: 0;
      right: 0;
      margin: 0 auto;
      opacity: 0;
      transition: all 0.5s ease 0.1s;
      img:nth-child(1){
        width: 100%;
        height: 100%;
      }
      img:nth-child(2){
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 83.8px;
        height: 83.8px;
        z-index: 2;
        background: #fff;
        border-radius: 50%;
      }
    }
    &-name{
      font-size:32px;
      // font-family:'fzqk';
      font-weight:bold;
      color:rgba(255,255,255,1);
      line-height:32px;
      position: absolute;
      top: 319px;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      transition: all 0.5s ease 0.1s;
    }
    &-desc{
      position: absolute;
      width: 305px;
      font-size:16px;
      color:rgba(255,255,255,1);
      line-height:30px;
      text-align: center;
      top: 383px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
    }
    &-desc1{
      position: absolute;
      width: 270px;
      font-size:20px;
      color:rgba(255,255,255,1);
      line-height:30px;
      text-align: center;
      bottom: 198px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      transition: all 0.5s ease;
      opacity: 0;
    }
    &:hover{
      .midder-item-bg{
        opacity: 1;
      }
      .midder-item-icon1{
        top: 0;
        opacity: 1;
      }
      .midder-item-icon{
        width: 227px;
        height: 227px;
        top: 0;
        opacity: 0;
      }
      .midder-item-name{
        top: 197px;
      }
      .midder-item-line{
        opacity: 1;
      }
      .midder-item-desc{
        width: 247px;
        font-size:20px;
        color:rgba(255,255,255,1);
        line-height:30px;
        top: 393px;
      }
      .midder-item-desc1{
        bottom: 168px;
        opacity: 1;
      }
    }
  }
}
.reg-box{
  width: 100%;
  height: 340px;
  overflow: hidden;
  background:linear-gradient(-90deg,rgba(255,96,0,1),rgba(255,144,0,1));
  // font-family: 'fzqk';
  .reg-title{
    font-size:50px;
    line-height: 50px;
    margin: 82px auto 56px;
    font-weight:bold;
    text-align: center;
    color:rgba(255,255,255,1);
  }
  .reg-btn{
    cursor: pointer;
    width:300px;
    height:68px;
    line-height: 68px;
    text-align: center;
    background:rgba(255,255,255,0.15);
    border:2px solid rgba(255,255,255,1);
    font-size:30px;
    font-weight:bold;
    color:rgba(255,255,255,1);
    border-radius:34px;
    margin: 0 auto;
  }
}
.footer{
  width: 100%;
  .footer-content{
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .footer-line{
    width: 1094px;
    height: 16px;
    margin: 0 auto 80px;
  }
  .footer-contact{
    margin: 100px 0 78px;
  }
  .footer-logo{
    width: 153px;
    height: 36px;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .footer-info{
    display: flex;
    align-items: center;
    margin-bottom: 53px;
    &-list{
      display: flex;
    }
    &-item{
      margin-left: 100px;
      font-size: 14px;
      color: #666666;
      div:nth-child(1){
        line-height: 14px;
        margin-bottom: 30px;
      }
      div:nth-child(2){
        max-width: 300px;
        line-height: 24px;
      }
    }
  }
  .footer-sign{
    display: flex;
    align-items: center;
    font-size:12px;
    color:rgba(186,186,186,1);
    line-height: 12px;
    margin-bottom: 50px;
    &-desc{
      margin: 0 30px;
      a{
        display: inline-block;
        color:rgba(186,186,186,1);
        text-decoration: none;
      }
    }
    &-line{
      width: 164px;
      height:1px;
      background:rgba(221,221,221,1);
    }
  }
}
.warning-btn {
  color: #fff;
  background-color: #FF7800;
  width: 130px;
  height: 56px;
  position: fixed;
  right: 60px;
  bottom: 7%;
  &.el-button--warning {
    background-color: #FF7800;
  }
}

.warning-alert::-webkit-scrollbar {
  display:none
}

.warning-alert {
  color: #666666;
  font-size: 16px;
  line-height: 2;

  /deep/.el-dialog__body {
    padding: 0 65px 50px;
  }

  h1 {
    text-align: center;
    color: #222222;
    font-size: 30px;
  }

  h2 {
    color: #333333;
    font-size: 18px;
  }

  p {
    text-align: right;
  }

  .logo {
    margin-top: 30px;
    margin-bottom: 10px;
    text-align: right;
    height: 26px;
  }

  img {
    float: right;
    width: 94px;
    height: 26px;
  }

  .close-btn {
    position: absolute;
    bottom: -80px;
    left: 50%;
    transform: translate(-50%);
    width: 40px;
    height: 40px;
    background: url("~@/img/home-warning-alert-close.png") no-repeat center;
    background-size: 40px 40px;
    cursor: pointer;
  }
}

/deep/.el-dialog__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
// 解决饿了么弹窗使body右侧padding+15的问题
body {
  padding-right:0 !important;
}

@keyframes rightan {
  from{
    bottom: 5%;
    opacity: 1;
  }
  to{
    bottom: 0%;
    opacity: 0;
  }
}
.section{
  overflow: hidden;
}
.section-title{
  //font-family: 'fzqk';
  padding-top: 140px;
  font-size:42px;
  font-weight:bold;
  color:rgba(255,255,255,1);
  line-height:42px;
  margin-bottom: 40px;
  text-align: center;
}
.section-desc{
  width: 1146px;
  font-size:22px;
  color:rgba(255,255,255,1);
  line-height:36px;
  text-align: center;
  margin: 0 auto;
}
/////////////////////page2
.section2{
  background-image: url('../img/section2-bg.png');
  background-size: cover;
  &-map{
    position: relative;
    width: 1047px;
    height: 650px;
    margin: 83px auto 0;
    &-img1{
      width: 1047px;
      height: 650px;
    }
    &-img2{
      position: absolute;
      top: 38px;
      left: 78px;
      width: 938px;
      height: 483px;
      z-index: 2;
    }
    &-sign{
      position: absolute;
      left: -37px;
      bottom: 23px;
      display: flex;
      align-items: center;
      z-index: 3;
    }
    &-icon{
      position: relative;
      width:20px;
      height:20px;
      background:rgba(255,132,0,0.2);
      border-radius:50%;
      margin-right: 16px;
      div{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width:8px;
        height:8px;
        background:rgba(255,132,0,1);
        border-radius:50%;
      }
    }
    &-name{
      font-size:18px;
      // font-family:'fzqk';
      color:rgba(255,255,255,1);
      line-height:18px;
    }
  }
}
.map-name{
  position: absolute;
  left: 818px;
  top: 279px;
  font-size:14px;
  color:rgba(255,255,255,1);
  line-height:14px;
}
.map-list{
  position: absolute;
  top: 0;
  left: 0;
  width: 1047px;
  height: 650px;
  z-index: 2;
}
@keyframes circleAnimationIn {
  0% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.0;
  }
  25% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0.0;
  }
}
@keyframes circleAnimationOut {
  0% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.0;
  }
  25% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.3);
    -webkit-transform: scale(0.3);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
    opacity: 0.0;
  }
}
.map-item{
  position: absolute;
  width:26px;
  height:26px;
  .dot {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FF8400;
    z-index: 2;
    left: 50%;
    top: 50%;
    margin-left: -4px;
    margin-top: -4px;
  }
  .inner-circle {
    position: absolute;
    z-index: 1;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid #FF8400;
    animation: circleAnimationIn 2s ease-out;
    animation-iteration-count: infinite;
    box-shadow: 1px 1px 20px #FF8400;
  }
  .outer-circle {
    position: absolute;
    z-index: 1;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid #FF8400;
    animation: circleAnimationOut 2s ease-out;
    animation-iteration-count: infinite;
    box-shadow: 1px 1px 20px #FF8400;
  }
}
.map-item1{
  left: 374px;
  top: 33px;
}
.map-item2{
  left: 525px;
  top: 25px;
}
.map-item3{
  left: 63px;
  top: 123px;
}
.map-item4{
  left: 762px;
  top: 88px;
}
.map-item5{
  left: 960px;
  top: 88px;
}
.map-item6{
  left: 147px;
  top: 219px;
}
.map-item7{
  left: 509px;
  top: 158px;
}
.map-item8{
  left: 625px;
  top: 124px;
}
.map-item9{
  left: 727px;
  top: 141px;
}
.map-item10{
  left: 801px;
  top: 133px;
}
.map-item11{
  left: 860px;
  top: 128px;
}
.map-item12{
  left: 814px;
  top: 172px;
}
.map-item13{
  left: 860px;
  top: 128px;
}
.map-item14{
  left: 753px;
  top: 196px;
}
.map-item15{
  left: 793px;
  top: 204px;
}
.map-item16{
  left: 893px;
  top: 214px;
}
.map-item17{
  left: 823px;
  top: 229px;
}
.map-item18{
  left: 692px;
  top: 237px;
}
.map-item19{
  left: 615px;
  top: 266px;
}
.map-item20{
  left: 723px;
  top: 258px;
}
.map-item21{
  left: 817px;
  top: 247px;
  .dot{
    background:#FF0000;
    width: 18px;
    height: 18px;
  }
}
.map-item22{
  left: 866px;
  top: 263px;
}
.map-item23{
  left: 546px;
  top: 330px;
}
.map-item24{
  left: 715px;
  top: 310px;
}
.map-item25{
  left: 812px;
  top: 292px;
}
.map-item26{
  left: 310px;
  top: 435px;
}
.map-item27{
  left: 625px;
  top: 444px;
}
.map-item28{
  left: 865px;
  top: 437px;
}
.map-item29{
  left: 916px;
  top: 380px;
}
.map-item30{
  left: 1001px;
  top: 508px;
}
//////////////page4
.section4{
  background-image: url('../img/section4-bg.png');
  background-size: cover;
}
.swiper{
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  &-box{
    position: relative;
    width: 890px;
    height: 480px;
    margin: 0 100px;
  }
  &-left,&-right{
    width: 50px;
    height: 86px;
    cursor: pointer;
  }
  &-img-box{
    position: absolute;
    left: 0;
    top: 0;
    width: 680px;
    height:480px;
    background:linear-gradient(-90deg,rgba(255,96,0,1),rgba(255,144,0,1));
  }
  &-img{
    position: absolute;
    top: 40px;
    left: 40px;
    height: 480px;
  }
  &-text-box{
    position: absolute;
    top: 20px;
    z-index: 2;
    right: 0;
    width:450px;
    height:440px;
    background:rgba(255,255,255,1);
    box-sizing: border-box;
    padding: 60px 50px 0;
  }
  &-title{
    font-size:34px;
    color:rgba(255,132,0,1);
    line-height:34px;
    margin-bottom: 20px;
    // font-family: 'fzqk';
  }
  &-line{
    width:30px;
    height:4px;
    background:rgba(255,120,0,1);
    margin-bottom: 25px;
  }
  &-desc{
    font-size:20px;
    color:rgba(102,102,102,1);
    line-height:32px;
  }
}
//////////////page5
.section5{
  background-image: url('../img/section5-bg.png');
  background-size: cover;
  &-img{
    width: 1098px;
    height: 531px;
    margin: 90px auto 0;
  }
}

//////page6
.section6{
  background-image: url('../img/section6-bg.png');
  background-size: cover;
  .sectionWarp{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .section-title{
    padding-top: 130px;
  }
  .section-desc{
    font-size: 15px;
    font-weight: 400;
  }
  .section-list{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    align-items: center;
    margin: 0 auto;
    margin-top: -50px;
    position: relative;
    height: 532px;
    .section-item{
      background-image: url('../img/section-list-item-bg-small.png');
      width: 305px;
      height: 340px;
      cursor: pointer;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      margin-top: 40px;
      position: absolute;
      .section-item-header{
        width: 53px;
        height: 53px;
        margin-top: 28px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .section-item-title{
        font-size: 18px;
        margin-top: 11px;
        line-height: 20px;
        margin-bottom: 13px;
      }
      .section-item-bar{
        font-family: PingFang SC;
        font-weight: 400;
        color: #FF7800;
        font-size: 11px;
        line-height: 20px;
      }
      .section-item-line{
        width: 167px;
        height: 8px;
        margin-top: 17px;
      }
      .section-item-text{
        width: 210px;
        margin-top: 14px;
        font-size: 11px;
        line-height: 20px;
        text-align: center;
        transform: scale(.8);
      }
      .section-item-btn{
          text-align: center;
          background-image: url('../img/section-item-btn.png');
          background-size: cover;
          width: 93px;
          height: 47px;
          background-repeat: no-repeat;
          line-height: 24px;
          font-size: 12px;
          margin-top: 10px;
      }
      
    }
    .section-item-big{
      background-image: url('../img/section-list-item-bg.png');
      width: 532px;
      height: 532px;
      margin-top: 0;
      padding: 0;
      .section-item-header{
        margin-top: 135px;
      }
      .section-item-title{
        font-size: 23px;
        margin-top: 11px;
        line-height: 36px;
      }
      .section-item-bar{
        font-size: 11px;
        line-height: 20px;
      }
      .section-item-text{
        width: 200px;
        margin-top: 14px;
        font-size: 11px;
        line-height: 20px;
        transform: scale(1);
      }
    }
    .section-item-warp{
        min-width: 296px;
        min-height: 340px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
  }
}
.section7{
   background-image: url('../img/section7-bg.png');
    background-size: cover;
    display: flex;
    justify-content: center;
  .section-voice{
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 100%;
    justify-content: space-around;
    padding-top: 150px;
    .section-voice-left{
      display: flex;
      flex-direction: column;
      width: 413px;
      margin-top: 40px;
      .section-voice-title{
        font-size: 31px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px;
      }
      .section-voice-bar{
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #F47800;
        line-height: 24px;
         margin-top: 21px;
      }
      .section-voice-slogn{
        font-size: 27px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 67px;
         margin-top: 70px;
         min-width: 450px;
         text-align: center;
      }
      .section-voice-slogn-name{
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px;
        text-align: right;
      }
      .section-voice-slogn-btn{
        margin: 0 auto;
        background-image: url('../img/section-voice-slogn-btn.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px;
        height: 71px;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
        margin-top: 38px;
      }
    }
    .section-voice-right{
      display: flex;
      flex-direction: column;
      margin-left: 130px;
      align-items: center;
      transform: scale(.8);
      margin-top: -75px;
      position: relative;
      .section-voice-phone-title{
        background-image: url('../img/section-voice-phone-title.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 268px;
        height: 60px;
        font-size: 17px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 32px;
        line-height: 50px;
        text-align: center;
      }
      .section-voice-phone{
        width: 290px;
        height: 590px;
        position: absolute;
        z-index: 2;
        top: 60px;
      }
      .section-voice-phone-mp4{
        width: 369px;
        height: 560px;
        position: absolute;
        z-index: 1;
        top: 75px;
        video{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.section8{
   background-image: url('../img/section8-bg.png');
    background-size: cover;
    .advert-effect{
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      position: relative;
      height: 100%;
      justify-content: space-between;
      .advert-effect-title{
        font-size: 31px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px;
        margin-top: 123px;
      }
      .advert-effect-bar{
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        margin-top: 21px;
      }
      .advert-effect-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px;
        .advert-effect-start{
           background-image: url('../img/advert-effect-start.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 440px;
          height: 440px;
          display: flex;
          flex-direction: column;
          align-items: center;
          .advert-effect-header{
            width: 57px;
            height: 57px;
            margin-top: 152px;
          }
          .advert-effect-text{
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 33px;
            width: 170px;
            margin-top: 15px;
          }
        }
        .advert-effect-bottombg{
          background-image: url('../img/advert-effect-bottombg.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 1021px;
          height: 265px;
          margin-top: -190px;
          position: relative;
          top: 0;
          left: 0;
          .advert-effect-bottom-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: absolute;
            left: 51px;
            top: -135px;
            .advert-effect-item-title{
              background-image: url('../img/advert-effect-item-active.png');
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px;
              height: 93px;
              color: #FFFFFF;
              z-index: 9;
              cursor: pointer;
              position: absolute;
              transform-style: preserve-3d;
              backface-visibility: hidden;
              transition: all 1s;
              transform: rotateY(0);
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 5px;
              margin-top: 2px;
              .advert-effect-item-title-top{
                width: 35px;
                height: 35px;
                margin-top: 11px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FF7800;
                line-height: 24px;
                
              }
            } 
            .advert-effect-item-line{
              width: 1px;
              height: 100px;
              background: #FF7800;
              margin-top: -20px;
            }
            .advert-effect-item-round{
              width: 21px;
              height: 21px;
            }
            .advert-effect-item-active{
              background-image: url('../img/advert-effect-item-title.png');
              width: 183px;
              height: 135px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 0px;
              transform: rotateY(-180deg);
              .advert-effect-item-title-top{
                margin-top: 40px;
                width: 100%;
              }
              .advert-effect-item-title-bottom{
                font-size: 9px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 15px;
                margin-top: -5px;
                transform: scale(.8);
                width: 100%;
                text-align: center;
                opacity: 0;
                margin-left: 5px;
              }
              .font-top{
                margin-top: -12px;
              }
            }
            .advert-effect-item-title-warp{
              width: 183px;
              height: 135px;
              margin-left: -5px;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .advert-effect-item-title-warp-active{
                .advert-effect-item-title{
                  transform: rotateY(-180deg);
                }
                .advert-effect-item-active{
                  transform: rotateY(0);
                  .advert-effect-item-title-bottom{
                    opacity: 1;
                  }
                }
              }
          }
          
          .advert-effect-item-one{
              left: 210px;
              top: -225px;
              .advert-effect-item-line{
                height: 166px;
              }
              .advert-effect-item-title{
                .advert-effect-item-line{
                  margin-top: 0;
                }
              }
              
            }
          .advert-effect-item-two{
              left: 168px;
              top: -18px;
              z-index: 99;
              .advert-effect-item-line{
                height: 80px;
              }
          }
          .advert-effect-item-three{
              left: 645px;
              top: -210px;
              .advert-effect-item-line{
                height: 147px;
              }
          }
          .advert-effect-item-four{
              left: 774px;
              top: -64px;
          }
        }
        
        
      }
      .advert-effect-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px;
        height: 66px;
        text-align: center;
        line-height: 40px;
        position: absolute;
        left: 60px;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px;
      }
    }
}
.section9{
  background-image: url('../img/section9-bg.png');
  background-size: cover;
  .section-profit{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .profit-title{
      margin-top: 120px;
      font-size: 31px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px;
    }
    .profit-bar{
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
      margin-top: 20px;
    }
    .profit-content{
      display: flex;
      flex-direction: row;
      .profit-left{
        display: flex;
        flex-direction: column;
        .profit-content-list{
          display: flex;
          flex-direction: column;
          .profit-content-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px;
            .profit-content-item-header{
              width: 39px;
              height: 44px;
            }
            .profit-content-item-text{
              margin-left: 17px;
              width: 270px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px;
            }
          }
        }
        .profit-bottom{
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 67px;
          margin-top: 20px;
          .profit-bottom-btn{
            background-image: url('../img/profit-bottom-btn.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 29px;
            width: 160px;
            height: 71px;
            text-align: center;
            line-height: 50px;
            font-size: 19px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .profit-right{
        // background-image: url('../img/profit-right-bg.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        width: 477px;
        height: 477px;
        display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px;
        .profit-right-comtent{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 0;
          left: 0;
          margin-left: 70px;
          .profit-right-comtent-img{
            width: 358px;
            height: 358px;
            z-index: 5;
          }
          .profit-right-comtent-bg{
            position: absolute;
            width: 477px;
            height: 477px;
            z-index: 1;
            animation: rotateWarp 4s linear infinite 2.5s;
          }
          .profit-right-comtent-sml{
            width: 477px;
            height: 477px;
            z-index: 2;
            animation: rotateWarpSml 4s linear infinite 1.5s;
          }
          .profit-right-comtent-within{
            width: 477px;
            height: 477px;
            z-index: 3;
          }
        }
      }
    }
  }
  @keyframes rotateWarp {
    0%{
       transform: rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}
  @keyframes rotateWarpSml{
    0%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  
}
.section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 123px;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px;
            height: 29px;
          }
          .demonstration-path-item-text{
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px;
            width: 75px;
            margin-left: 4px;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px;
            height: 15px;
            margin: 0 29px;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px;
        width: 250px;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px;
            height: 87px;
          }
          .demonstration-content-item-text{
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px;
        height: 471px;
        margin: 0 20px;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px;
        height: 471px;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px;
        position:absolute;
        img{
          border-radius: 50px;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px;
        width: 96%;
        margin: 0 auto;
        height: 471px;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px;
        height: 66px;
        text-align: center;
        line-height: 40px;
        position: absolute;
        right: 60px;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px;
    }
  }
}
.fade-enter{
    opacity: 0;
  }
  .fade-enter-active{
    transition: opacity 1s;
  }
  .fade-leave-to{
    opacity: 0;
  }
  .fade-leave-active{
    transition: opacity 1s;
  }
@media screen and (max-width: 1440px) {
  .header {
    .header-content {
      .header-title {
        margin-bottom: 50px;
        &-desc {
          font-size: 86px;
          line-height: 86px;
          span {
            margin: 0 60px;
          }
        }
        img {
          width: 445px;
          height: 104px;
        }
      }
      .header-sign {
        font-size: 33px;
        line-height: 33px;
      }
      .header-sb {
        width: 43px;
        height: 81px;
        left: 0;
        right: 0;
        bottom: 32px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .header-playbtn{
        cursor: pointer;
        position: absolute;
        right: 30px;
        bottom: 30px;
        width: 68px;
        height: 26px;
        border: 1px solid #fff;
        border-radius: 13px;
        line-height: 26px;
        text-align: center;
      }
    }
  }
  .midder{
    &-title{
      font-size:50px;
      line-height:50px;
    }
    &-item{
      margin-right: 35px;
      width: 258px;
      height: 386px;
      &-bg{
        width: 258px;
        height: 286px;
      }
      &-line{
        width: 163px;
        height: 7px;
        bottom: 102px;
      }
      &-icon{
        width: 228px;
        height: 228px;
        top: 33px;
        img:nth-child(2){
          width: 104px;
          height: 104px;
        }
      }
      &-icon1{
        width: 182px;
        height: 182px;
        top: 33px;
        img:nth-child(2){
          width:62px;
          height: 62px;
        }
      }
      &-name{
        font-size:24px;
        line-height:24px;
        top: 252px;
      }
      &-desc{
        width: 229px;
        font-size:12px;
        line-height:22px;
        top: 294px;
      }
      &-desc1{
        width: 200px;
        font-size:15px;
        line-height:22px;
        top: 230px;
      }
      &:hover{
        .midder-item-bg{
          opacity: 1;
        }
        .midder-item-icon1{
          top: 0;
          opacity: 1;
        }
        .midder-item-icon{
          width: 182px;
          height: 182px;
          top: 0;
          opacity: 0;
        }
        .midder-item-name{
          top: 154px;
        }
        .midder-item-line{
          opacity: 1;
        }
        .midder-item-desc{
          width: 185px;
          font-size:15px;
          color:rgba(255,255,255,1);
          line-height:22px;
          top: 300px;
        }
        .midder-item-desc1{
          top: 200px;
          opacity: 1;
        }
      }
    }
  }
  .reg-box{
    width: 100%;
    height: 255px;
    .reg-title{
      font-size:38px;
      line-height: 38px;
      margin: 62px auto 42px;
      // font-family: 'fzqk';
    }
    .reg-btn{
      width:222px;
      height:50px;
      line-height: 50px;
      font-size:22px;
      // font-family: 'fzqk';
      border-radius:25px;
    }
  }
  .footer{
    width: 100%;
    .footer-logo{
      width: 114px;
      height: 27px;
    }
    .footer-line{
      width: 820px;
      height: 10px;
      margin: 0 auto 50px;
    }
    .footer-contact{
      margin: 50px 0 50px;
    }
    .footer-info{
      margin-bottom: 42px;
      &-item{
        margin-left: 80px;
        font-size: 12px;
        div:nth-child(1){
          line-height: 12px;
          margin-bottom: 22px;
        }
        div:nth-child(2){
          max-width: 237px;
          line-height: 18px;
        }
      }
    }
    .footer-sign{
      display: flex;
      align-items: center;
      font-size:12px;
      color:rgba(186,186,186,1);
      line-height: 12px;
      margin-bottom: 37px;
      &-desc{
        margin: 0 20px;
      }
      &-line{
        width: 120px;
        height:1px;
        background:rgba(221,221,221,1);
      }
    }
  }
  .section-title{
    padding-top: 103px;
    font-size:32px;
    line-height:32px;
    margin-bottom: 30px;
  }
  .section-desc{
    width: 833px;
    font-size: 16px;
    line-height:27px;
  }
  /////////////////////page2
  .section2{
    &-map{
      width: 786px;
      height: 488px;
      margin: 62px auto 0;
      &-img1{
        width: 786px;
        height: 488px;
      }
      &-img2{
        top: 30px;
        left: 60px;
        width: 703px;
        height: 362px;
      }
      &-sign{
        left: -21px;
        bottom: 12px;
      }
      &-icon{
        width:16px;
        height:16px;
        margin-right: 10px;
        div{
          width:5px;
          height:5px;
        }
      }
      &-name{
        font-size:14px;
        line-height:14px;
      }
    }
  }
  .map-name{
    left: 616px;
    top: 216px;
    font-size: 12px;
    line-height: 12px;
  }
  .map-item{
    width:20px;
    height:20px;
    .dot {
      width: 8px;
      height: 8px;
      margin-left: -3px;
      margin-top: -3px;
    }
    .inner-circle {
      width: 20px;
      height: 20px;
      box-shadow: 1px 1px 10px #FF8400;
    }
    .outer-circle {
      width: 20px;
      height: 20px;
      border: 1px solid #FF8400;
      box-shadow: 1px 1px 10px #FF8400;
    }
  }
  .map-item1{
    left: 282px;
    top: 26px;
  }
  .map-item2{
    left: 395px;
    top: 20px;
  }
  .map-item3{
    left: 48px;
    top: 93px;
  }
  .map-item4{
    left: 573px;
    top: 67px;
  }
  .map-item5{
    left: 721px;
    top: 67px;
  }
  .map-item6{
    left: 111px;
    top: 165px;
  }
  .map-item7{
    left: 383px;
    top: 119px;
  }
  .map-item8{
    left: 470px;
    top: 94px;
  }
  .map-item9{
    left: 546px;
    top: 107px;
  }
  .map-item10{
    left: 602px;
    top: 101px;
  }
  .map-item11{
    left: 646px;
    top: 97px;
  }
  .map-item12{
    left: 612px;
    top: 130px;
  }
  .map-item13{
    left: 642px;
    top: 130px;
  }
  .map-item14{
    left: 569px;
    top: 148px;
  }
  .map-item15{
    left: 596px;
    top: 154px;
  }
  .map-item16{
    left: 671px;
    top: 161px;
  }
  .map-item17{
    left: 617px;
    top: 172px;
  }
  .map-item18{
    left: 520px;
    top: 179px;
  }
  .map-item19{
    left: 462px;
    top: 200px;
  }
  .map-item20{
    left: 543px;
    top: 194px;
  }
  .map-item21{

    left: 613px;
    top: 186px;
    .dot{
      width: 14px;
      height: 14px;
      background:#FF0000;
    }
    .inner-circle {
      width: 26px;
      height: 26px;
      box-shadow: 1px 1px 16px #FF0000;
    }
    .outer-circle {
      width: 26px;
      height: 26px;
      border: 1px solid #FF0000;
      box-shadow: 1px 1px 16px #FF0000;
    }
  }
  .map-item22{
    left: 651px;
    top: 198px;
  }
  .map-item23{
    left: 411px;
    top: 248px;
  }
  .map-item24{
    left: 537px;
    top: 233px;
  }
  .map-item25{
    left: 610px;
    top: 220px;
  }
  .map-item26{
    left: 234px;
    top: 327px;
  }
  .map-item27{
    left: 470px;
    top: 334px;
  }
  .map-item28{
    left: 650px;
    top: 329px;
  }
  .map-item29{
    left: 688px;
    top: 286px;
  }
  .map-item30{
    left: 752px;
    top: 380px;
  }
  ///////////page4
  .swiper{
    padding-top: 75px;
    &-box{
      width: 668px;
      height: 360px;
      margin: 0 74px;
    }
    &-left,&-right{
      width: 38px;
      height: 64px;
    }
    &-img-box{
      width:510px;
      height:360px;
    }
    &-img{
      top: 30px;
      left: 30px;
      height: 300px;
    }
    &-text-box{
      top: 15px;
      width:338px;
      height:330px;
      background:rgba(255,255,255,1);
      padding: 45px 37px 0;
    }
    &-title{
      font-size:26px;
      line-height:26px;
      margin-bottom: 14px;
    }
    &-line{
      width:22px;
      height:3px;
      margin-bottom: 18px;
    }
    &-desc{
      font-size:16px;
      line-height:28px;
    }
  }
  //////////////page5
  .section5{
    &-img{
      width: 824px;
      height: 399px;
      margin: 65px auto 0;
    }
  }
}
@media screen and (min-width: 1590px){
  $big:1.1;
  .section6{
    background-image: url('../img/section6-bg.png');
    background-size: cover;
    .sectionWarp{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    .section-title{
      padding-top: 130px*$big;
      font-size: 31px*$big;
      padding-top: 10%;
    }
    .section-desc{
      font-size: 15px*$big;
      font-weight: 400;
    }
    .section-list{
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content:space-around;
      align-items: center;
      margin: 0 auto;
      margin-top: -50px*$big;
      position: relative;
      height: 532px*$big;
      .section-item{
        background-image: url('../img/section-list-item-bg-small.png');
        width: 305px*$big;
        height: 340px*$big;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        margin-top: 40px*$big;
        position: absolute;
        .section-item-header{
          width: 53px*$big;
          height: 53px*$big;
          margin-top: 28px*$big;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .section-item-title{
          font-size: 18px*$big;
          margin-top: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-bar{
          font-family: PingFang SC;
          font-weight: 400;
          color: #FF7800;
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-line{
          width: 167px*$big;
          height: 8px*$big;
          margin-top: 17px*$big;
        }
        .section-item-text{
          width: 240px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          text-align: center;
        }
        .section-item-btn{
            text-align: center;
            background-image: url('../img/section-item-btn.png');
            background-size: cover;
            width: 93px*$big;
            height: 47px*$big;
            background-repeat: no-repeat;
            line-height: 24px*$big;
            font-size: 12px*$big;
            margin-top: 10px*$big;
        }
        
      }
      .section-item-big{
        background-image: url('../img/section-list-item-bg.png');
        width: 532px*$big;
        height: 532px*$big;
        margin-top: 0;
        padding: 0;
        .section-item-header{
          margin-top: 135px*$big;
        }
        .section-item-title{
          font-size: 23px*$big;
          margin-top: 11px*$big;
          line-height: 36px*$big;
        }
        .section-item-bar{
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-text{
          width: 260px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          transform: scale(1);
        }
      }
      .section-item-warp{
          min-width: 296px*$big;
          min-height: 340px*$big;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
      }
    }
  }
  .section7{
   background-image: url('../img/section7-bg.png');
    background-size: cover;
    display: flex;
    justify-content: center;
  .section-voice{
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 100%;
    justify-content: space-around;
    padding-top: 18%;
    .section-voice-left{
      display: flex;
      flex-direction: column;
      width: 413px*$big;
      margin-top: 40px*$big;
      .section-voice-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
      }
      .section-voice-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #F47800;
        line-height: 24px*$big;
         margin-top: 21px*$big;
      }
      .section-voice-slogn{
        font-size: 27px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 67px*$big;
         margin-top: 70px*$big;
         min-width: 450px*$big;
         text-align: center;
      }
      .section-voice-slogn-name{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px*$big;
        text-align: right;
      }
      .section-voice-slogn-btn{
        margin: 0 auto;
        background-image: url('../img/section-voice-slogn-btn.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px*$big;
        height: 71px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 50px*$big;
        text-align: center;
        cursor: pointer;
        margin-top: 38px*$big;
      }
    }
    .section-voice-right{
      display: flex;
      flex-direction: column;
      margin-left: 130px*$big;
      align-items: center;
      transform: scale(.8);
      margin-top: -75px*$big;
      position: relative;
      .section-voice-phone-title{
        background-image: url('../img/section-voice-phone-title.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 268px*$big;
        height: 60px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 32px*$big;
        line-height: 50px*$big;
        text-align: center;
      }
      .section-voice-phone{
        width: 290px*$big;
        height: 590px*$big;
        position: absolute;
        z-index: 2;
        top: 60px*$big;
      }
      .section-voice-phone-mp4{
        width: 369px*$big;
        height: 560px*$big;
        position: absolute;
        z-index: 1;
        top: 75px*$big;
        video{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  }
  .section8{
   background-image: url('../img/section8-bg.png');
    background-size: cover;
    .advert-effect{
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      position: relative;
      height: 100%;
      justify-content: space-between;
      .advert-effect-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
        margin-top: 10%;
      }
      .advert-effect-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px*$big;
        margin-top: 21px*$big;
      }
      .advert-effect-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px*$big;
        .advert-effect-start{
           background-image: url('../img/advert-effect-start.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 440px*$big;
          height: 440px*$big;
          display: flex;
          flex-direction: column;
          align-items: center;
          .advert-effect-header{
            width: 57px*$big;
            height: 57px*$big;
            margin-top: 152px*$big;
          }
          .advert-effect-text{
            font-size: 20px*$big;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 33px*$big;
            width: 170px*$big;
            margin-top: 15px*$big;
          }
        }
        .advert-effect-bottombg{
          background-image: url('../img/advert-effect-bottombg.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 1021px*$big;
          height: 265px*$big;
          margin-top: -190px*$big;
          position: relative;
          top: 0;
          left: 0;
          .advert-effect-bottom-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: absolute;
            left: 51px*$big;
            top: -135px*$big;
            .advert-effect-item-title{
              background-image: url('../img/advert-effect-item-active.png');
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px*$big;
              height: 93px*$big;
              color: #FFFFFF;
              z-index: 9;
              cursor: pointer;
              position: absolute;
              transform-style: preserve-3d;
              backface-visibility: hidden;
              transition: all 1s;
              transform: rotateY(0);
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 5px*$big;
              margin-top: 2px*$big;
              .advert-effect-item-title-top{
                width: 35px*$big;
                height: 35px*$big;
                margin-top: 11px*$big;
                font-size: 16px*$big;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px*$big;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FF7800;
                line-height: 24px*$big;
                
              }
            } 
            .advert-effect-item-line{
              width: 1px*$big;
              height: 100px*$big;
              background: #FF7800;
              margin-top: -20px*$big;
            }
            .advert-effect-item-round{
              width: 21px*$big;
              height: 21px*$big;
            }
            .advert-effect-item-active{
              background-image: url('../img/advert-effect-item-title.png');
              width: 183px*$big;
              height: 135px*$big;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 0px;
              transform: rotateY(-180deg);
              .advert-effect-item-title-top{
                margin-top: 40px*$big;
                width: 100%;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 15px*$big;
                margin-top: -5px*$big;
                // transform: scale(.8);
                width: 100%;
                text-align: center;
                opacity: 0;
                margin-left: 5px*$big;
              }
              .font-top{
                margin-top: -12px*$big;
              }
            }
            .advert-effect-item-title-warp{
              width: 183px*$big;
              height: 135px*$big;
              margin-left: -5px*$big;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .advert-effect-item-title-warp-active{
                .advert-effect-item-title{
                  transform: rotateY(-180deg);
                }
                .advert-effect-item-active{
                  transform: rotateY(0);
                  .advert-effect-item-title-bottom{
                    opacity: 1;
                  }
                }
              }
          }
          
          .advert-effect-item-one{
              left: 210px*$big;
              top: -225px*$big;
              .advert-effect-item-line{
                height: 166px*$big;
              }
              .advert-effect-item-title{
                .advert-effect-item-line{
                  margin-top: 0;
                }
              }
              
            }
          .advert-effect-item-two{
              left: 168px*$big;
              top: -18px*$big;
              z-index: 99;
              .advert-effect-item-line{
                height: 80px*$big;
              }
          }
          .advert-effect-item-three{
              left: 645px*$big;
              top: -210px*$big;
              .advert-effect-item-line{
                height: 147px*$big;
              }
          }
          .advert-effect-item-four{
              left: 774px*$big;
              top: -64px*$big;
          }
        }
        
        
      }
      .advert-effect-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        left: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
      }
    }
  }
  .section9{
  background-image: url('../img/section9-bg.png');
  background-size: cover;
  .section-profit{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .profit-title{
      margin-top: 10%;
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px*$big;
    }
    .profit-bar{
      font-size: 15px*$big;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px*$big;
      margin-top: 20px*$big;
    }
    .profit-content{
      display: flex;
      flex-direction: row;
       margin-bottom: 3%;
      .profit-left{
        display: flex;
        flex-direction: column;
        .profit-content-list{
          display: flex;
          flex-direction: column;
          .profit-content-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px*$big;
            .profit-content-item-header{
              width: 39px*$big;
              height: 44px*$big;
            }
            .profit-content-item-text{
              margin-left: 17px*$big;
              width: 270px*$big;
              font-size: 15px*$big;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px*$big;
            }
          }
        }
        .profit-bottom{
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px*$big;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 67px*$big;
          margin-top: 20px*$big;
          .profit-bottom-btn{
            background-image: url('../img/profit-bottom-btn.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 29px*$big;
            width: 160px*$big;
            height: 71px*$big;
            text-align: center;
            line-height: 50px*$big;
            font-size: 19px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .profit-right{
        // background-image: url('../img/profit-right-bg.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        width: 477px*$big;
        height: 477px*$big;
        display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px*$big;
        .profit-right-comtent{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 0;
          left: 0;
          margin-left: 70px*$big;
          .profit-right-comtent-img{
            width: 358px*$big;
            height: 358px*$big;
            z-index: 5;
          }
          .profit-right-comtent-bg{
            position: absolute;
            width: 477px*$big;
            height: 477px*$big;
            z-index: 1;
            animation: rotateWarp 4s linear infinite 2.5s;
          }
          .profit-right-comtent-sml{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 2;
            animation: rotateWarpSml 4s linear infinite 1.5s;
          }
          .profit-right-comtent-within{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 3;
          }
        }
      }
    }
  }
  @keyframes rotateWarp {
    0%{
       transform: rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}
  @keyframes rotateWarpSml{
    0%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  
  }
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
    }
  }
  }
}
@media screen and (min-width: 1700px){
  $big:1.2;
  .section6{
    background-image: url('../img/section6-bg.png');
    background-size: cover;
    .sectionWarp{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    .section-title{
      padding-top: 130px*$big;
      font-size: 31px*$big;
      padding-top: 10%;
    }
    .section-desc{
      font-size: 15px*$big;
      font-weight: 400;
    }
    .section-list{
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content:space-around;
      align-items: center;
      margin: 0 auto;
      margin-top: -50px*$big;
      position: relative;
      height: 532px*$big;
      .section-item{
        background-image: url('../img/section-list-item-bg-small.png');
        width: 305px*$big;
        height: 340px*$big;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        margin-top: 40px*$big;
        position: absolute;
        .section-item-header{
          width: 53px*$big;
          height: 53px*$big;
          margin-top: 28px*$big;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .section-item-title{
          font-size: 18px*$big;
          margin-top: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-bar{
          font-family: PingFang SC;
          font-weight: 400;
          color: #FF7800;
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-line{
          width: 167px*$big;
          height: 8px*$big;
          margin-top: 17px*$big;
        }
        .section-item-text{
          width: 240px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          text-align: center;
        }
        .section-item-btn{
            text-align: center;
            background-image: url('../img/section-item-btn.png');
            background-size: cover;
            width: 93px*$big;
            height: 47px*$big;
            background-repeat: no-repeat;
            line-height: 24px*$big;
            font-size: 12px*$big;
            margin-top: 10px*$big;
        }
        
      }
      .section-item-big{
        background-image: url('../img/section-list-item-bg.png');
        width: 532px*$big;
        height: 532px*$big;
        margin-top: 0;
        padding: 0;
        .section-item-header{
          margin-top: 135px*$big;
        }
        .section-item-title{
          font-size: 23px*$big;
          margin-top: 11px*$big;
          line-height: 36px*$big;
        }
        .section-item-bar{
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-text{
          width: 260px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          transform: scale(1);
        }
      }
      .section-item-warp{
          min-width: 296px*$big;
          min-height: 340px*$big;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
      }
    }
  }
  .section7{
   background-image: url('../img/section7-bg.png');
    background-size: cover;
    display: flex;
    justify-content: center;
  .section-voice{
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 100%;
    justify-content: space-around;
    padding-top: 18%;
    .section-voice-left{
      display: flex;
      flex-direction: column;
      width: 413px*$big;
      margin-top: 40px*$big;
      .section-voice-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
      }
      .section-voice-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #F47800;
        line-height: 24px*$big;
         margin-top: 21px*$big;
      }
      .section-voice-slogn{
        font-size: 27px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 67px*$big;
         margin-top: 70px*$big;
         min-width: 450px*$big;
         text-align: center;
      }
      .section-voice-slogn-name{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px*$big;
        text-align: right;
      }
      .section-voice-slogn-btn{
        margin: 0 auto;
        background-image: url('../img/section-voice-slogn-btn.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px*$big;
        height: 71px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 50px*$big;
        text-align: center;
        cursor: pointer;
        margin-top: 38px*$big;
      }
    }
    .section-voice-right{
      display: flex;
      flex-direction: column;
      margin-left: 130px*$big;
      align-items: center;
      transform: scale(.8);
      margin-top: -75px*$big;
      position: relative;
      .section-voice-phone-title{
        background-image: url('../img/section-voice-phone-title.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 268px*$big;
        height: 60px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 32px*$big;
        line-height: 50px*$big;
        text-align: center;
      }
      .section-voice-phone{
        width: 290px*$big;
        height: 590px*$big;
        position: absolute;
        z-index: 2;
        top: 60px*$big;
      }
      .section-voice-phone-mp4{
        width: 369px*$big;
        height: 560px*$big;
        position: absolute;
        z-index: 1;
        top: 75px*$big;
        video{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  }
  .section8{
   background-image: url('../img/section8-bg.png');
    background-size: cover;
    .advert-effect{
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      position: relative;
      height: 100%;
      justify-content: space-between;
      .advert-effect-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
        margin-top: 10%;
      }
      .advert-effect-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px*$big;
        margin-top: 21px*$big;
      }
      .advert-effect-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px*$big;
        .advert-effect-start{
           background-image: url('../img/advert-effect-start.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 440px*$big;
          height: 440px*$big;
          display: flex;
          flex-direction: column;
          align-items: center;
          .advert-effect-header{
            width: 57px*$big;
            height: 57px*$big;
            margin-top: 152px*$big;
          }
          .advert-effect-text{
            font-size: 20px*$big;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 33px*$big;
            width: 170px*$big;
            margin-top: 15px*$big;
          }
        }
        .advert-effect-bottombg{
          background-image: url('../img/advert-effect-bottombg.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 1021px*$big;
          height: 265px*$big;
          margin-top: -190px*$big;
          position: relative;
          top: 0;
          left: 0;
          .advert-effect-bottom-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: absolute;
            left: 51px*$big;
            top: -135px*$big;
            .advert-effect-item-title{
              background-image: url('../img/advert-effect-item-active.png');
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px*$big;
              height: 93px*$big;
              color: #FFFFFF;
              z-index: 9;
              cursor: pointer;
              position: absolute;
              transform-style: preserve-3d;
              backface-visibility: hidden;
              transition: all 1s;
              transform: rotateY(0);
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 5px*$big;
              margin-top: 2px*$big;
              .advert-effect-item-title-top{
                width: 35px*$big;
                height: 35px*$big;
                margin-top: 11px*$big;
                font-size: 16px*$big;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px*$big;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FF7800;
                line-height: 24px*$big;
                
              }
            } 
            .advert-effect-item-line{
              width: 1px*$big;
              height: 100px*$big;
              background: #FF7800;
              margin-top: -20px*$big;
            }
            .advert-effect-item-round{
              width: 21px*$big;
              height: 21px*$big;
            }
            .advert-effect-item-active{
              background-image: url('../img/advert-effect-item-title.png');
              width: 183px*$big;
              height: 135px*$big;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 0px;
              transform: rotateY(-180deg);
              .advert-effect-item-title-top{
                margin-top: 40px*$big;
                width: 100%;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 15px*$big;
                margin-top: -5px*$big;
                // transform: scale(.8);
                width: 100%;
                text-align: center;
                opacity: 0;
                margin-left: 5px*$big;
              }
              .font-top{
                margin-top: -12px*$big;
              }
            }
            .advert-effect-item-title-warp{
              width: 183px*$big;
              height: 135px*$big;
              margin-left: -5px*$big;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .advert-effect-item-title-warp-active{
                .advert-effect-item-title{
                  transform: rotateY(-180deg);
                }
                .advert-effect-item-active{
                  transform: rotateY(0);
                  .advert-effect-item-title-bottom{
                    opacity: 1;
                  }
                }
              }
          }
          
          .advert-effect-item-one{
              left: 210px*$big;
              top: -225px*$big;
              .advert-effect-item-line{
                height: 166px*$big;
              }
              .advert-effect-item-title{
                .advert-effect-item-line{
                  margin-top: 0;
                }
              }
              
            }
          .advert-effect-item-two{
              left: 168px*$big;
              top: -18px*$big;
              z-index: 99;
              .advert-effect-item-line{
                height: 80px*$big;
              }
          }
          .advert-effect-item-three{
              left: 645px*$big;
              top: -210px*$big;
              .advert-effect-item-line{
                height: 147px*$big;
              }
          }
          .advert-effect-item-four{
              left: 774px*$big;
              top: -64px*$big;
          }
        }
        
        
      }
      .advert-effect-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        left: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
      }
    }
  }
  .section9{
  background-image: url('../img/section9-bg.png');
  background-size: cover;
  .section-profit{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .profit-title{
      margin-top: 10%;
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px*$big;
    }
    .profit-bar{
      font-size: 15px*$big;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px*$big;
      margin-top: 20px*$big;
    }
    .profit-content{
      display: flex;
      flex-direction: row;
       margin-bottom: 3%;
      .profit-left{
        display: flex;
        flex-direction: column;
        .profit-content-list{
          display: flex;
          flex-direction: column;
          .profit-content-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px*$big;
            .profit-content-item-header{
              width: 39px*$big;
              height: 44px*$big;
            }
            .profit-content-item-text{
              margin-left: 17px*$big;
              width: 270px*$big;
              font-size: 15px*$big;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px*$big;
            }
          }
        }
        .profit-bottom{
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px*$big;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 67px*$big;
          margin-top: 20px*$big;
          .profit-bottom-btn{
            background-image: url('../img/profit-bottom-btn.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 29px*$big;
            width: 160px*$big;
            height: 71px*$big;
            text-align: center;
            line-height: 50px*$big;
            font-size: 19px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .profit-right{
        // background-image: url('../img/profit-right-bg.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        width: 477px*$big;
        height: 477px*$big;
        display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px*$big;
        .profit-right-comtent{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 0;
          left: 0;
          margin-left: 70px*$big;
          .profit-right-comtent-img{
            width: 358px*$big;
            height: 358px*$big;
            z-index: 5;
          }
          .profit-right-comtent-bg{
            position: absolute;
            width: 477px*$big;
            height: 477px*$big;
            z-index: 1;
            animation: rotateWarp 4s linear infinite 2.5s;
          }
          .profit-right-comtent-sml{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 2;
            animation: rotateWarpSml 4s linear infinite 1.5s;
          }
          .profit-right-comtent-within{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 3;
          }
        }
      }
    }
  }
  @keyframes rotateWarp {
    0%{
       transform: rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}
  @keyframes rotateWarpSml{
    0%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  
  }
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
    }
  }
  }
}
@media screen and (min-width: 1770px){
  $big:1.3;
  .section6{
    background-image: url('../img/section6-bg.png');
    background-size: cover;
    .sectionWarp{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    .section-title{
      padding-top: 130px*$big;
      font-size: 31px*$big;
      padding-top: 10%;
    }
    .section-desc{
      font-size: 15px*$big;
      font-weight: 400;
    }
    .section-list{
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content:space-around;
      align-items: center;
      margin: 0 auto;
      margin-top: -50px*$big;
      position: relative;
      height: 532px*$big;
      .section-item{
        background-image: url('../img/section-list-item-bg-small.png');
        width: 305px*$big;
        height: 340px*$big;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        margin-top: 40px*$big;
        position: absolute;
        .section-item-header{
          width: 53px*$big;
          height: 53px*$big;
          margin-top: 28px*$big;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .section-item-title{
          font-size: 18px*$big;
          margin-top: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-bar{
          font-family: PingFang SC;
          font-weight: 400;
          color: #FF7800;
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-line{
          width: 167px*$big;
          height: 8px*$big;
          margin-top: 17px*$big;
        }
        .section-item-text{
          width: 240px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          text-align: center;
        }
        .section-item-btn{
            text-align: center;
            background-image: url('../img/section-item-btn.png');
            background-size: cover;
            width: 93px*$big;
            height: 47px*$big;
            background-repeat: no-repeat;
            line-height: 24px*$big;
            font-size: 12px*$big;
            margin-top: 10px*$big;
        }
        
      }
      .section-item-big{
        background-image: url('../img/section-list-item-bg.png');
        width: 532px*$big;
        height: 532px*$big;
        margin-top: 0;
        padding: 0;
        .section-item-header{
          margin-top: 135px*$big;
        }
        .section-item-title{
          font-size: 23px*$big;
          margin-top: 11px*$big;
          line-height: 36px*$big;
        }
        .section-item-bar{
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-text{
          width: 260px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          transform: scale(1);
        }
      }
      .section-item-warp{
          min-width: 296px*$big;
          min-height: 340px*$big;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
      }
    }
  }
  .section7{
   background-image: url('../img/section7-bg.png');
    background-size: cover;
    display: flex;
    justify-content: center;
  .section-voice{
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 100%;
    justify-content: space-around;
    padding-top: 18%;
    .section-voice-left{
      display: flex;
      flex-direction: column;
      width: 413px*$big;
      margin-top: 40px*$big;
      .section-voice-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
      }
      .section-voice-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #F47800;
        line-height: 24px*$big;
         margin-top: 21px*$big;
      }
      .section-voice-slogn{
        font-size: 27px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 67px*$big;
         margin-top: 70px*$big;
         min-width: 450px*$big;
         text-align: center;
      }
      .section-voice-slogn-name{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px*$big;
        text-align: right;
      }
      .section-voice-slogn-btn{
        margin: 0 auto;
        background-image: url('../img/section-voice-slogn-btn.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px*$big;
        height: 71px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 50px*$big;
        text-align: center;
        cursor: pointer;
        margin-top: 38px*$big;
      }
    }
    .section-voice-right{
      display: flex;
      flex-direction: column;
      margin-left: 130px*$big;
      align-items: center;
      transform: scale(.8);
      margin-top: -75px*$big;
      position: relative;
      .section-voice-phone-title{
        background-image: url('../img/section-voice-phone-title.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 268px*$big;
        height: 60px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 32px*$big;
        line-height: 50px*$big;
        text-align: center;
      }
      .section-voice-phone{
        width: 290px*$big;
        height: 590px*$big;
        position: absolute;
        z-index: 2;
        top: 60px*$big;
      }
      .section-voice-phone-mp4{
        width: 369px*$big;
        height: 560px*$big;
        position: absolute;
        z-index: 1;
        top: 75px*$big;
        video{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  }
  .section8{
   background-image: url('../img/section8-bg.png');
    background-size: cover;
    .advert-effect{
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      position: relative;
      height: 100%;
      justify-content: space-between;
      .advert-effect-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
        margin-top: 10%;
      }
      .advert-effect-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px*$big;
        margin-top: 21px*$big;
      }
      .advert-effect-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px*$big;
        .advert-effect-start{
           background-image: url('../img/advert-effect-start.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 440px*$big;
          height: 440px*$big;
          display: flex;
          flex-direction: column;
          align-items: center;
          .advert-effect-header{
            width: 57px*$big;
            height: 57px*$big;
            margin-top: 152px*$big;
          }
          .advert-effect-text{
            font-size: 20px*$big;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 33px*$big;
            width: 170px*$big;
            margin-top: 15px*$big;
          }
        }
        .advert-effect-bottombg{
          background-image: url('../img/advert-effect-bottombg.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 1021px*$big;
          height: 265px*$big;
          margin-top: -190px*$big;
          position: relative;
          top: 0;
          left: 0;
          .advert-effect-bottom-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: absolute;
            left: 51px*$big;
            top: -135px*$big;
            .advert-effect-item-title{
              background-image: url('../img/advert-effect-item-active.png');
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px*$big;
              height: 93px*$big;
              color: #FFFFFF;
              z-index: 9;
              cursor: pointer;
              position: absolute;
              transform-style: preserve-3d;
              backface-visibility: hidden;
              transition: all 1s;
              transform: rotateY(0);
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 5px*$big;
              margin-top: 2px*$big;
              .advert-effect-item-title-top{
                width: 35px*$big;
                height: 35px*$big;
                margin-top: 11px*$big;
                font-size: 16px*$big;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px*$big;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FF7800;
                line-height: 24px*$big;
                
              }
            } 
            .advert-effect-item-line{
              width: 1px*$big;
              height: 100px*$big;
              background: #FF7800;
              margin-top: -20px*$big;
            }
            .advert-effect-item-round{
              width: 21px*$big;
              height: 21px*$big;
            }
            .advert-effect-item-active{
              background-image: url('../img/advert-effect-item-title.png');
              width: 183px*$big;
              height: 135px*$big;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 0px;
              transform: rotateY(-180deg);
              .advert-effect-item-title-top{
                margin-top: 40px*$big;
                width: 100%;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 15px*$big;
                margin-top: -5px*$big;
                // transform: scale(.8);
                width: 100%;
                text-align: center;
                opacity: 0;
                margin-left: 5px*$big;
              }
              .font-top{
                margin-top: -12px*$big;
              }
            }
            .advert-effect-item-title-warp{
              width: 183px*$big;
              height: 135px*$big;
              margin-left: -5px*$big;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .advert-effect-item-title-warp-active{
                .advert-effect-item-title{
                  transform: rotateY(-180deg);
                }
                .advert-effect-item-active{
                  transform: rotateY(0);
                  .advert-effect-item-title-bottom{
                    opacity: 1;
                  }
                }
              }
          }
          
          .advert-effect-item-one{
              left: 210px*$big;
              top: -225px*$big;
              .advert-effect-item-line{
                height: 166px*$big;
              }
              .advert-effect-item-title{
                .advert-effect-item-line{
                  margin-top: 0;
                }
              }
              
            }
          .advert-effect-item-two{
              left: 168px*$big;
              top: -18px*$big;
              z-index: 99;
              .advert-effect-item-line{
                height: 80px*$big;
              }
          }
          .advert-effect-item-three{
              left: 645px*$big;
              top: -210px*$big;
              .advert-effect-item-line{
                height: 147px*$big;
              }
          }
          .advert-effect-item-four{
              left: 774px*$big;
              top: -64px*$big;
          }
        }
        
        
      }
      .advert-effect-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        left: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
      }
    }
  }
  .section9{
  background-image: url('../img/section9-bg.png');
  background-size: cover;
  .section-profit{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .profit-title{
      margin-top: 10%;
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px*$big;
    }
    .profit-bar{
      font-size: 15px*$big;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px*$big;
      margin-top: 20px*$big;
    }
    .profit-content{
      display: flex;
      flex-direction: row;
       margin-bottom: 3%;
      .profit-left{
        display: flex;
        flex-direction: column;
        .profit-content-list{
          display: flex;
          flex-direction: column;
          .profit-content-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px*$big;
            .profit-content-item-header{
              width: 39px*$big;
              height: 44px*$big;
            }
            .profit-content-item-text{
              margin-left: 17px*$big;
              width: 270px*$big;
              font-size: 15px*$big;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px*$big;
            }
          }
        }
        .profit-bottom{
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px*$big;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 67px*$big;
          margin-top: 20px*$big;
          .profit-bottom-btn{
            background-image: url('../img/profit-bottom-btn.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 29px*$big;
            width: 160px*$big;
            height: 71px*$big;
            text-align: center;
            line-height: 50px*$big;
            font-size: 19px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .profit-right{
        // background-image: url('../img/profit-right-bg.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        width: 477px*$big;
        height: 477px*$big;
        display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px*$big;
        .profit-right-comtent{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 0;
          left: 0;
          margin-left: 70px*$big;
          .profit-right-comtent-img{
            width: 358px*$big;
            height: 358px*$big;
            z-index: 5;
          }
          .profit-right-comtent-bg{
            position: absolute;
            width: 477px*$big;
            height: 477px*$big;
            z-index: 1;
            animation: rotateWarp 4s linear infinite 2.5s;
          }
          .profit-right-comtent-sml{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 2;
            animation: rotateWarpSml 4s linear infinite 1.5s;
          }
          .profit-right-comtent-within{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 3;
          }
        }
      }
    }
  }
  @keyframes rotateWarp {
    0%{
       transform: rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}
  @keyframes rotateWarpSml{
    0%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  
  }
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
    }
  }
  }
}
@media screen and (min-width: 1900px){
  $big:1.4;
  .section6{
    background-image: url('../img/section6-bg.png');
    background-size: cover;
    .sectionWarp{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    .section-title{
      padding-top: 130px*$big;
      font-size: 31px*$big;
      padding-top: 10%;
    }
    .section-desc{
      font-size: 15px*$big;
      font-weight: 400;
    }
    .section-list{
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content:space-around;
      align-items: center;
      margin: 0 auto;
      margin-top: -50px*$big;
      position: relative;
      height: 532px*$big;
      .section-item{
        background-image: url('../img/section-list-item-bg-small.png');
        width: 305px*$big;
        height: 340px*$big;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        margin-top: 40px*$big;
        position: absolute;
        .section-item-header{
          width: 53px*$big;
          height: 53px*$big;
          margin-top: 28px*$big;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .section-item-title{
          font-size: 18px*$big;
          margin-top: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-bar{
          font-family: PingFang SC;
          font-weight: 400;
          color: #FF7800;
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-line{
          width: 167px*$big;
          height: 8px*$big;
          margin-top: 17px*$big;
        }
        .section-item-text{
          width: 240px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          text-align: center;
        }
        .section-item-btn{
            text-align: center;
            background-image: url('../img/section-item-btn.png');
            background-size: cover;
            width: 93px*$big;
            height: 47px*$big;
            background-repeat: no-repeat;
            line-height: 24px*$big;
            font-size: 12px*$big;
            margin-top: 10px*$big;
        }
        
      }
      .section-item-big{
        background-image: url('../img/section-list-item-bg.png');
        width: 532px*$big;
        height: 532px*$big;
        margin-top: 0;
        padding: 0;
        .section-item-header{
          margin-top: 135px*$big;
        }
        .section-item-title{
          font-size: 23px*$big;
          margin-top: 11px*$big;
          line-height: 36px*$big;
        }
        .section-item-bar{
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-text{
          width: 260px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          transform: scale(1);
        }
      }
      .section-item-warp{
          min-width: 296px*$big;
          min-height: 340px*$big;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
      }
    }
  }
  .section7{
   background-image: url('../img/section7-bg.png');
    background-size: cover;
    display: flex;
    justify-content: center;
  .section-voice{
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 100%;
    justify-content: space-around;
    padding-top: 10%;
    .section-voice-left{
      display: flex;
      flex-direction: column;
      width: 413px*$big;
      margin-top: 40px*$big;
      .section-voice-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
      }
      .section-voice-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #F47800;
        line-height: 24px*$big;
         margin-top: 21px*$big;
      }
      .section-voice-slogn{
        font-size: 27px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 67px*$big;
         margin-top: 70px*$big;
         min-width: 450px*$big;
         text-align: center;
      }
      .section-voice-slogn-name{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px*$big;
        text-align: right;
      }
      .section-voice-slogn-btn{
        margin: 0 auto;
        background-image: url('../img/section-voice-slogn-btn.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px*$big;
        height: 71px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 50px*$big;
        text-align: center;
        cursor: pointer;
        margin-top: 38px*$big;
      }
    }
    .section-voice-right{
      display: flex;
      flex-direction: column;
      margin-left: 130px*$big;
      align-items: center;
      transform: scale(.8);
      margin-top: -75px*$big;
      position: relative;
      .section-voice-phone-title{
        background-image: url('../img/section-voice-phone-title.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 268px*$big;
        height: 60px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 32px*$big;
        line-height: 50px*$big;
        text-align: center;
      }
      .section-voice-phone{
        width: 290px*$big;
        height: 590px*$big;
        position: absolute;
        z-index: 2;
        top: 60px*$big;
      }
      .section-voice-phone-mp4{
        width: 369px*$big;
        height: 560px*$big;
        position: absolute;
        z-index: 1;
        top: 75px*$big;
        video{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  }
  .section8{
   background-image: url('../img/section8-bg.png');
    background-size: cover;
    .advert-effect{
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      position: relative;
      height: 100%;
      justify-content: space-between;
      .advert-effect-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
        margin-top: 10%;
      }
      .advert-effect-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px*$big;
        margin-top: 21px*$big;
      }
      .advert-effect-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px*$big;
        .advert-effect-start{
           background-image: url('../img/advert-effect-start.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 440px*$big;
          height: 440px*$big;
          display: flex;
          flex-direction: column;
          align-items: center;
          .advert-effect-header{
            width: 57px*$big;
            height: 57px*$big;
            margin-top: 152px*$big;
          }
          .advert-effect-text{
            font-size: 20px*$big;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 33px*$big;
            width: 170px*$big;
            margin-top: 15px*$big;
          }
        }
        .advert-effect-bottombg{
          background-image: url('../img/advert-effect-bottombg.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 1021px*$big;
          height: 265px*$big;
          margin-top: -190px*$big;
          position: relative;
          top: 0;
          left: 0;
          .advert-effect-bottom-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: absolute;
            left: 51px*$big;
            top: -135px*$big;
            .advert-effect-item-title{
              background-image: url('../img/advert-effect-item-active.png');
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px*$big;
              height: 93px*$big;
              color: #FFFFFF;
              z-index: 9;
              cursor: pointer;
              position: absolute;
              transform-style: preserve-3d;
              backface-visibility: hidden;
              transition: all 1s;
              transform: rotateY(0);
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 5px*$big;
              margin-top: 2px*$big;
              .advert-effect-item-title-top{
                width: 35px*$big;
                height: 35px*$big;
                margin-top: 11px*$big;
                font-size: 16px*$big;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px*$big;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FF7800;
                line-height: 24px*$big;
                
              }
            } 
            .advert-effect-item-line{
              width: 1px*$big;
              height: 100px*$big;
              background: #FF7800;
              margin-top: -20px*$big;
            }
            .advert-effect-item-round{
              width: 21px*$big;
              height: 21px*$big;
            }
            .advert-effect-item-active{
              background-image: url('../img/advert-effect-item-title.png');
              width: 183px*$big;
              height: 135px*$big;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 0px;
              transform: rotateY(-180deg);
              .advert-effect-item-title-top{
                margin-top: 40px*$big;
                width: 100%;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 15px*$big;
                margin-top: -5px*$big;
                // transform: scale(.8);
                width: 100%;
                text-align: center;
                opacity: 0;
                margin-left: 5px*$big;
              }
              .font-top{
                margin-top: -12px*$big;
              }
            }
            .advert-effect-item-title-warp{
              width: 183px*$big;
              height: 135px*$big;
              margin-left: -5px*$big;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .advert-effect-item-title-warp-active{
                .advert-effect-item-title{
                  transform: rotateY(-180deg);
                }
                .advert-effect-item-active{
                  transform: rotateY(0);
                  .advert-effect-item-title-bottom{
                    opacity: 1;
                  }
                }
              }
          }
          
          .advert-effect-item-one{
              left: 210px*$big;
              top: -225px*$big;
              .advert-effect-item-line{
                height: 166px*$big;
              }
              .advert-effect-item-title{
                .advert-effect-item-line{
                  margin-top: 0;
                }
              }
              
            }
          .advert-effect-item-two{
              left: 168px*$big;
              top: -18px*$big;
              z-index: 99;
              .advert-effect-item-line{
                height: 80px*$big;
              }
          }
          .advert-effect-item-three{
              left: 645px*$big;
              top: -210px*$big;
              .advert-effect-item-line{
                height: 147px*$big;
              }
          }
          .advert-effect-item-four{
              left: 774px*$big;
              top: -64px*$big;
          }
        }
        
        
      }
      .advert-effect-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        left: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
      }
    }
  }
  .section9{
  background-image: url('../img/section9-bg.png');
  background-size: cover;
  .section-profit{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .profit-title{
      margin-top: 10%;
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px*$big;
    }
    .profit-bar{
      font-size: 15px*$big;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px*$big;
      margin-top: 20px*$big;
    }
    .profit-content{
      display: flex;
      flex-direction: row;
       margin-bottom: 3%;
      .profit-left{
        display: flex;
        flex-direction: column;
        .profit-content-list{
          display: flex;
          flex-direction: column;
          .profit-content-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px*$big;
            .profit-content-item-header{
              width: 39px*$big;
              height: 44px*$big;
            }
            .profit-content-item-text{
              margin-left: 17px*$big;
              width: 270px*$big;
              font-size: 15px*$big;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px*$big;
            }
          }
        }
        .profit-bottom{
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px*$big;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 67px*$big;
          margin-top: 20px*$big;
          .profit-bottom-btn{
            background-image: url('../img/profit-bottom-btn.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 29px*$big;
            width: 160px*$big;
            height: 71px*$big;
            text-align: center;
            line-height: 50px*$big;
            font-size: 19px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .profit-right{
        // background-image: url('../img/profit-right-bg.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        width: 477px*$big;
        height: 477px*$big;
        display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px*$big;
        .profit-right-comtent{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 0;
          left: 0;
          margin-left: 70px*$big;
          .profit-right-comtent-img{
            width: 358px*$big;
            height: 358px*$big;
            z-index: 5;
          }
          .profit-right-comtent-bg{
            position: absolute;
            width: 477px*$big;
            height: 477px*$big;
            z-index: 1;
            animation: rotateWarp 4s linear infinite 2.5s;
          }
          .profit-right-comtent-sml{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 2;
            animation: rotateWarpSml 4s linear infinite 1.5s;
          }
          .profit-right-comtent-within{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 3;
          }
        }
      }
    }
  }
  @keyframes rotateWarp {
    0%{
       transform: rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}
  @keyframes rotateWarpSml{
    0%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  
  }
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
    }
  }
  }
}
@media screen and (min-width: 2200px){
  $big:1.8;
  .section6{
    background-image: url('../img/section6-bg.png');
    background-size: cover;
    .sectionWarp{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    .section-title{
      padding-top: 130px*$big;
      font-size: 31px*$big;
      padding-top: 10%;
    }
    .section-desc{
      font-size: 15px*$big;
      font-weight: 400;
    }
    .section-list{
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content:space-around;
      align-items: center;
      margin: 0 auto;
      margin-top: -50px*$big;
      position: relative;
      height: 532px*$big;
      .section-item{
        background-image: url('../img/section-list-item-bg-small.png');
        width: 305px*$big;
        height: 340px*$big;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        margin-top: 40px*$big;
        position: absolute;
        .section-item-header{
          width: 53px*$big;
          height: 53px*$big;
          margin-top: 28px*$big;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .section-item-title{
          font-size: 18px*$big;
          margin-top: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-bar{
          font-family: PingFang SC;
          font-weight: 400;
          color: #FF7800;
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-line{
          width: 167px*$big;
          height: 8px*$big;
          margin-top: 17px*$big;
        }
        .section-item-text{
          width: 240px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          text-align: center;
        }
        .section-item-btn{
            text-align: center;
            background-image: url('../img/section-item-btn.png');
            background-size: cover;
            width: 93px*$big;
            height: 47px*$big;
            background-repeat: no-repeat;
            line-height: 24px*$big;
            font-size: 12px*$big;
            margin-top: 10px*$big;
        }
        
      }
      .section-item-big{
        background-image: url('../img/section-list-item-bg.png');
        width: 532px*$big;
        height: 532px*$big;
        margin-top: 0;
        padding: 0;
        .section-item-header{
          margin-top: 135px*$big;
        }
        .section-item-title{
          font-size: 23px*$big;
          margin-top: 11px*$big;
          line-height: 36px*$big;
        }
        .section-item-bar{
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-text{
          width: 260px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          transform: scale(1);
        }
      }
      .section-item-warp{
          min-width: 296px*$big;
          min-height: 340px*$big;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
      }
    }
  }
  .section7{
   background-image: url('../img/section7-bg.png');
    background-size: cover;
    display: flex;
    justify-content: center;
  .section-voice{
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 100%;
    justify-content: space-around;
    padding-top: 10%;
    .section-voice-left{
      display: flex;
      flex-direction: column;
      width: 413px*$big;
      margin-top: 40px*$big;
      .section-voice-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
      }
      .section-voice-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #F47800;
        line-height: 24px*$big;
         margin-top: 21px*$big;
      }
      .section-voice-slogn{
        font-size: 27px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 67px*$big;
         margin-top: 70px*$big;
         min-width: 450px*$big;
         text-align: center;
      }
      .section-voice-slogn-name{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px*$big;
        text-align: right;
      }
      .section-voice-slogn-btn{
        margin: 0 auto;
        background-image: url('../img/section-voice-slogn-btn.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px*$big;
        height: 71px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 50px*$big;
        text-align: center;
        cursor: pointer;
        margin-top: 38px*$big;
      }
    }
    .section-voice-right{
      display: flex;
      flex-direction: column;
      margin-left: 130px*$big;
      align-items: center;
      transform: scale(.8);
      margin-top: -75px*$big;
      position: relative;
      .section-voice-phone-title{
        background-image: url('../img/section-voice-phone-title.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 268px*$big;
        height: 60px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 32px*$big;
        line-height: 50px*$big;
        text-align: center;
      }
      .section-voice-phone{
        width: 290px*$big;
        height: 590px*$big;
        position: absolute;
        z-index: 2;
        top: 60px*$big;
      }
      .section-voice-phone-mp4{
        width: 369px*$big;
        height: 560px*$big;
        position: absolute;
        z-index: 1;
        top: 75px*$big;
        video{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  }
  .section8{
   background-image: url('../img/section8-bg.png');
    background-size: cover;
    .advert-effect{
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      position: relative;
      height: 100%;
      justify-content: space-between;
      .advert-effect-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
        margin-top: 10%;
      }
      .advert-effect-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px*$big;
        margin-top: 21px*$big;
      }
      .advert-effect-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px*$big;
        .advert-effect-start{
           background-image: url('../img/advert-effect-start.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 440px*$big;
          height: 440px*$big;
          display: flex;
          flex-direction: column;
          align-items: center;
          .advert-effect-header{
            width: 57px*$big;
            height: 57px*$big;
            margin-top: 152px*$big;
          }
          .advert-effect-text{
            font-size: 20px*$big;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 33px*$big;
            width: 170px*$big;
            margin-top: 15px*$big;
          }
        }
        .advert-effect-bottombg{
          background-image: url('../img/advert-effect-bottombg.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 1021px*$big;
          height: 265px*$big;
          margin-top: -190px*$big;
          position: relative;
          top: 0;
          left: 0;
          .advert-effect-bottom-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: absolute;
            left: 51px*$big;
            top: -135px*$big;
            .advert-effect-item-title{
              background-image: url('../img/advert-effect-item-active.png');
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px*$big;
              height: 93px*$big;
              color: #FFFFFF;
              z-index: 9;
              cursor: pointer;
              position: absolute;
              transform-style: preserve-3d;
              backface-visibility: hidden;
              transition: all 1s;
              transform: rotateY(0);
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 5px*$big;
              margin-top: 2px*$big;
              .advert-effect-item-title-top{
                width: 35px*$big;
                height: 35px*$big;
                margin-top: 11px*$big;
                font-size: 16px*$big;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px*$big;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FF7800;
                line-height: 24px*$big;
                
              }
            } 
            .advert-effect-item-line{
              width: 1px*$big;
              height: 100px*$big;
              background: #FF7800;
              margin-top: -20px*$big;
            }
            .advert-effect-item-round{
              width: 21px*$big;
              height: 21px*$big;
            }
            .advert-effect-item-active{
              background-image: url('../img/advert-effect-item-title.png');
              width: 183px*$big;
              height: 135px*$big;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 0px;
              transform: rotateY(-180deg);
              .advert-effect-item-title-top{
                margin-top: 40px*$big;
                width: 100%;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 15px*$big;
                margin-top: -5px*$big;
                // transform: scale(.8);
                width: 100%;
                text-align: center;
                opacity: 0;
                margin-left: 5px*$big;
              }
              .font-top{
                margin-top: -12px*$big;
              }
            }
            .advert-effect-item-title-warp{
              width: 183px*$big;
              height: 135px*$big;
              margin-left: -5px*$big;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .advert-effect-item-title-warp-active{
                .advert-effect-item-title{
                  transform: rotateY(-180deg);
                }
                .advert-effect-item-active{
                  transform: rotateY(0);
                  .advert-effect-item-title-bottom{
                    opacity: 1;
                  }
                }
              }
          }
          
          .advert-effect-item-one{
              left: 210px*$big;
              top: -225px*$big;
              .advert-effect-item-line{
                height: 166px*$big;
              }
              .advert-effect-item-title{
                .advert-effect-item-line{
                  margin-top: 0;
                }
              }
              
            }
          .advert-effect-item-two{
              left: 168px*$big;
              top: -18px*$big;
              z-index: 99;
              .advert-effect-item-line{
                height: 80px*$big;
              }
          }
          .advert-effect-item-three{
              left: 645px*$big;
              top: -210px*$big;
              .advert-effect-item-line{
                height: 147px*$big;
              }
          }
          .advert-effect-item-four{
              left: 774px*$big;
              top: -64px*$big;
          }
        }
        
        
      }
      .advert-effect-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        left: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
      }
    }
  }
  .section9{
  background-image: url('../img/section9-bg.png');
  background-size: cover;
  .section-profit{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .profit-title{
      margin-top: 10%;
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px*$big;
    }
    .profit-bar{
      font-size: 15px*$big;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px*$big;
      margin-top: 20px*$big;
    }
    .profit-content{
      display: flex;
      flex-direction: row;
       margin-bottom: 3%;
      .profit-left{
        display: flex;
        flex-direction: column;
        .profit-content-list{
          display: flex;
          flex-direction: column;
          .profit-content-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px*$big;
            .profit-content-item-header{
              width: 39px*$big;
              height: 44px*$big;
            }
            .profit-content-item-text{
              margin-left: 17px*$big;
              width: 270px*$big;
              font-size: 15px*$big;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px*$big;
            }
          }
        }
        .profit-bottom{
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px*$big;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 67px*$big;
          margin-top: 20px*$big;
          .profit-bottom-btn{
            background-image: url('../img/profit-bottom-btn.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 29px*$big;
            width: 160px*$big;
            height: 71px*$big;
            text-align: center;
            line-height: 50px*$big;
            font-size: 19px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .profit-right{
        // background-image: url('../img/profit-right-bg.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        width: 477px*$big;
        height: 477px*$big;
        display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px*$big;
        .profit-right-comtent{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 0;
          left: 0;
          margin-left: 70px*$big;
          .profit-right-comtent-img{
            width: 358px*$big;
            height: 358px*$big;
            z-index: 5;
          }
          .profit-right-comtent-bg{
            position: absolute;
            width: 477px*$big;
            height: 477px*$big;
            z-index: 1;
            animation: rotateWarp 4s linear infinite 2.5s;
          }
          .profit-right-comtent-sml{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 2;
            animation: rotateWarpSml 4s linear infinite 1.5s;
          }
          .profit-right-comtent-within{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 3;
          }
        }
      }
    }
  }
  @keyframes rotateWarp {
    0%{
       transform: rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}
  @keyframes rotateWarpSml{
    0%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  
  }
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
    }
  }
  }
}
@media screen and (min-width: 2800px){
  $big:2.4;
  .section6{
    background-image: url('../img/section6-bg.png');
    background-size: cover;
    .sectionWarp{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    .section-title{
      padding-top: 130px*$big;
      font-size: 31px*$big;
      padding-top: 10%;
    }
    .section-desc{
      font-size: 15px*$big;
      font-weight: 400;
    }
    .section-list{
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content:space-around;
      align-items: center;
      margin: 0 auto;
      margin-top: -50px*$big;
      position: relative;
      height: 532px*$big;
      .section-item{
        background-image: url('../img/section-list-item-bg-small.png');
        width: 305px*$big;
        height: 340px*$big;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        margin-top: 40px*$big;
        position: absolute;
        .section-item-header{
          width: 53px*$big;
          height: 53px*$big;
          margin-top: 28px*$big;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .section-item-title{
          font-size: 18px*$big;
          margin-top: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-bar{
          font-family: PingFang SC;
          font-weight: 400;
          color: #FF7800;
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-line{
          width: 167px*$big;
          height: 8px*$big;
          margin-top: 17px*$big;
        }
        .section-item-text{
          width: 240px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          text-align: center;
        }
        .section-item-btn{
            text-align: center;
            background-image: url('../img/section-item-btn.png');
            background-size: cover;
            width: 93px*$big;
            height: 47px*$big;
            background-repeat: no-repeat;
            line-height: 24px*$big;
            font-size: 12px*$big;
            margin-top: 10px*$big;
        }
        
      }
      .section-item-big{
        background-image: url('../img/section-list-item-bg.png');
        width: 532px*$big;
        height: 532px*$big;
        margin-top: 0;
        padding: 0;
        .section-item-header{
          margin-top: 135px*$big;
        }
        .section-item-title{
          font-size: 23px*$big;
          margin-top: 11px*$big;
          line-height: 36px*$big;
        }
        .section-item-bar{
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-text{
          width: 260px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          transform: scale(1);
        }
      }
      .section-item-warp{
          min-width: 296px*$big;
          min-height: 340px*$big;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
      }
    }
  }
  .section7{
   background-image: url('../img/section7-bg.png');
    background-size: cover;
    display: flex;
    justify-content: center;
  .section-voice{
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 100%;
    justify-content: space-around;
    padding-top: 10%;
    .section-voice-left{
      display: flex;
      flex-direction: column;
      width: 413px*$big;
      margin-top: 40px*$big;
      .section-voice-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
      }
      .section-voice-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #F47800;
        line-height: 24px*$big;
         margin-top: 21px*$big;
      }
      .section-voice-slogn{
        font-size: 27px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 67px*$big;
         margin-top: 70px*$big;
         min-width: 450px*$big;
         text-align: center;
      }
      .section-voice-slogn-name{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px*$big;
        text-align: right;
      }
      .section-voice-slogn-btn{
        margin: 0 auto;
        background-image: url('../img/section-voice-slogn-btn.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px*$big;
        height: 71px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 50px*$big;
        text-align: center;
        cursor: pointer;
        margin-top: 38px*$big;
      }
    }
    .section-voice-right{
      display: flex;
      flex-direction: column;
      margin-left: 130px*$big;
      align-items: center;
      transform: scale(.8);
      margin-top: -75px*$big;
      position: relative;
      .section-voice-phone-title{
        background-image: url('../img/section-voice-phone-title.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 268px*$big;
        height: 60px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 32px*$big;
        line-height: 50px*$big;
        text-align: center;
      }
      .section-voice-phone{
        width: 290px*$big;
        height: 590px*$big;
        position: absolute;
        z-index: 2;
        top: 60px*$big;
      }
      .section-voice-phone-mp4{
        width: 369px*$big;
        height: 560px*$big;
        position: absolute;
        z-index: 1;
        top: 75px*$big;
        video{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  }
  .section8{
   background-image: url('../img/section8-bg.png');
    background-size: cover;
    .advert-effect{
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      position: relative;
      height: 100%;
      justify-content: space-between;
      .advert-effect-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
        margin-top: 10%;
      }
      .advert-effect-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px*$big;
        margin-top: 21px*$big;
      }
      .advert-effect-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px*$big;
        .advert-effect-start{
           background-image: url('../img/advert-effect-start.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 440px*$big;
          height: 440px*$big;
          display: flex;
          flex-direction: column;
          align-items: center;
          .advert-effect-header{
            width: 57px*$big;
            height: 57px*$big;
            margin-top: 152px*$big;
          }
          .advert-effect-text{
            font-size: 20px*$big;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 33px*$big;
            width: 170px*$big;
            margin-top: 15px*$big;
          }
        }
        .advert-effect-bottombg{
          background-image: url('../img/advert-effect-bottombg.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 1021px*$big;
          height: 265px*$big;
          margin-top: -190px*$big;
          position: relative;
          top: 0;
          left: 0;
          .advert-effect-bottom-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: absolute;
            left: 51px*$big;
            top: -135px*$big;
            .advert-effect-item-title{
              background-image: url('../img/advert-effect-item-active.png');
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px*$big;
              height: 93px*$big;
              color: #FFFFFF;
              z-index: 9;
              cursor: pointer;
              position: absolute;
              transform-style: preserve-3d;
              backface-visibility: hidden;
              transition: all 1s;
              transform: rotateY(0);
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 5px*$big;
              margin-top: 2px*$big;
              .advert-effect-item-title-top{
                width: 35px*$big;
                height: 35px*$big;
                margin-top: 11px*$big;
                font-size: 16px*$big;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px*$big;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FF7800;
                line-height: 24px*$big;
                
              }
            } 
            .advert-effect-item-line{
              width: 1px*$big;
              height: 100px*$big;
              background: #FF7800;
              margin-top: -20px*$big;
            }
            .advert-effect-item-round{
              width: 21px*$big;
              height: 21px*$big;
            }
            .advert-effect-item-active{
              background-image: url('../img/advert-effect-item-title.png');
              width: 183px*$big;
              height: 135px*$big;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 0px;
              transform: rotateY(-180deg);
              .advert-effect-item-title-top{
                margin-top: 40px*$big;
                width: 100%;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 15px*$big;
                margin-top: -5px*$big;
                // transform: scale(.8);
                width: 100%;
                text-align: center;
                opacity: 0;
                margin-left: 5px*$big;
              }
              .font-top{
                margin-top: -12px*$big;
              }
            }
            .advert-effect-item-title-warp{
              width: 183px*$big;
              height: 135px*$big;
              margin-left: -5px*$big;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .advert-effect-item-title-warp-active{
                .advert-effect-item-title{
                  transform: rotateY(-180deg);
                }
                .advert-effect-item-active{
                  transform: rotateY(0);
                  .advert-effect-item-title-bottom{
                    opacity: 1;
                  }
                }
              }
          }
          
          .advert-effect-item-one{
              left: 210px*$big;
              top: -225px*$big;
              .advert-effect-item-line{
                height: 166px*$big;
              }
              .advert-effect-item-title{
                .advert-effect-item-line{
                  margin-top: 0;
                }
              }
              
            }
          .advert-effect-item-two{
              left: 168px*$big;
              top: -18px*$big;
              z-index: 99;
              .advert-effect-item-line{
                height: 80px*$big;
              }
          }
          .advert-effect-item-three{
              left: 645px*$big;
              top: -210px*$big;
              .advert-effect-item-line{
                height: 147px*$big;
              }
          }
          .advert-effect-item-four{
              left: 774px*$big;
              top: -64px*$big;
          }
        }
        
        
      }
      .advert-effect-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        left: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
      }
    }
  }
  .section9{
  background-image: url('../img/section9-bg.png');
  background-size: cover;
  .section-profit{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .profit-title{
      margin-top: 10%;
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px*$big;
    }
    .profit-bar{
      font-size: 15px*$big;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px*$big;
      margin-top: 20px*$big;
    }
    .profit-content{
      display: flex;
      flex-direction: row;
       margin-bottom: 3%;
      .profit-left{
        display: flex;
        flex-direction: column;
        .profit-content-list{
          display: flex;
          flex-direction: column;
          .profit-content-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px*$big;
            .profit-content-item-header{
              width: 39px*$big;
              height: 44px*$big;
            }
            .profit-content-item-text{
              margin-left: 17px*$big;
              width: 270px*$big;
              font-size: 15px*$big;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px*$big;
            }
          }
        }
        .profit-bottom{
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px*$big;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 67px*$big;
          margin-top: 20px*$big;
          .profit-bottom-btn{
            background-image: url('../img/profit-bottom-btn.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 29px*$big;
            width: 160px*$big;
            height: 71px*$big;
            text-align: center;
            line-height: 50px*$big;
            font-size: 19px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .profit-right{
        // background-image: url('../img/profit-right-bg.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        width: 477px*$big;
        height: 477px*$big;
        display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px*$big;
        .profit-right-comtent{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 0;
          left: 0;
          margin-left: 70px*$big;
          .profit-right-comtent-img{
            width: 358px*$big;
            height: 358px*$big;
            z-index: 5;
          }
          .profit-right-comtent-bg{
            position: absolute;
            width: 477px*$big;
            height: 477px*$big;
            z-index: 1;
            animation: rotateWarp 4s linear infinite 2.5s;
          }
          .profit-right-comtent-sml{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 2;
            animation: rotateWarpSml 4s linear infinite 1.5s;
          }
          .profit-right-comtent-within{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 3;
          }
        }
      }
    }
  }
  @keyframes rotateWarp {
    0%{
       transform: rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}
  @keyframes rotateWarpSml{
    0%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  
  }
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
    }
  }
  }
}
@media screen and (min-width: 3200px){
  $big:2.6;
  .section6{
    background-image: url('../img/section6-bg.png');
    background-size: cover;
    .sectionWarp{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    .section-title{
      padding-top: 130px*$big;
      font-size: 31px*$big;
      padding-top: 10%;
    }
    .section-desc{
      font-size: 15px*$big;
      font-weight: 400;
    }
    .section-list{
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content:space-around;
      align-items: center;
      margin: 0 auto;
      margin-top: -50px*$big;
      position: relative;
      height: 532px*$big;
      .section-item{
        background-image: url('../img/section-list-item-bg-small.png');
        width: 305px*$big;
        height: 340px*$big;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        margin-top: 40px*$big;
        position: absolute;
        .section-item-header{
          width: 53px*$big;
          height: 53px*$big;
          margin-top: 28px*$big;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .section-item-title{
          font-size: 18px*$big;
          margin-top: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-bar{
          font-family: PingFang SC;
          font-weight: 400;
          color: #FF7800;
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-line{
          width: 167px*$big;
          height: 8px*$big;
          margin-top: 17px*$big;
        }
        .section-item-text{
          width: 240px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          text-align: center;
        }
        .section-item-btn{
            text-align: center;
            background-image: url('../img/section-item-btn.png');
            background-size: cover;
            width: 93px*$big;
            height: 47px*$big;
            background-repeat: no-repeat;
            line-height: 24px*$big;
            font-size: 12px*$big;
            margin-top: 10px*$big;
        }
        
      }
      .section-item-big{
        background-image: url('../img/section-list-item-bg.png');
        width: 532px*$big;
        height: 532px*$big;
        margin-top: 0;
        padding: 0;
        .section-item-header{
          margin-top: 135px*$big;
        }
        .section-item-title{
          font-size: 23px*$big;
          margin-top: 11px*$big;
          line-height: 36px*$big;
        }
        .section-item-bar{
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-text{
          width: 260px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          transform: scale(1);
        }
      }
      .section-item-warp{
          min-width: 296px*$big;
          min-height: 340px*$big;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
      }
    }
  }
  .section7{
   background-image: url('../img/section7-bg.png');
    background-size: cover;
    display: flex;
    justify-content: center;
  .section-voice{
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 100%;
    justify-content: space-around;
    padding-top: 10%;
    .section-voice-left{
      display: flex;
      flex-direction: column;
      width: 413px*$big;
      margin-top: 40px*$big;
      .section-voice-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
      }
      .section-voice-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #F47800;
        line-height: 24px*$big;
         margin-top: 21px*$big;
      }
      .section-voice-slogn{
        font-size: 27px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 67px*$big;
         margin-top: 70px*$big;
         min-width: 450px*$big;
         text-align: center;
      }
      .section-voice-slogn-name{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px*$big;
        text-align: right;
      }
      .section-voice-slogn-btn{
        margin: 0 auto;
        background-image: url('../img/section-voice-slogn-btn.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px*$big;
        height: 71px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 50px*$big;
        text-align: center;
        cursor: pointer;
        margin-top: 38px*$big;
      }
    }
    .section-voice-right{
      display: flex;
      flex-direction: column;
      margin-left: 130px*$big;
      align-items: center;
      transform: scale(.8);
      margin-top: -75px*$big;
      position: relative;
      .section-voice-phone-title{
        background-image: url('../img/section-voice-phone-title.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 268px*$big;
        height: 60px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 32px*$big;
        line-height: 50px*$big;
        text-align: center;
      }
      .section-voice-phone{
        width: 290px*$big;
        height: 590px*$big;
        position: absolute;
        z-index: 2;
        top: 60px*$big;
      }
      .section-voice-phone-mp4{
        width: 369px*$big;
        height: 560px*$big;
        position: absolute;
        z-index: 1;
        top: 75px*$big;
        video{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  }
  .section8{
   background-image: url('../img/section8-bg.png');
    background-size: cover;
    .advert-effect{
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      position: relative;
      height: 100%;
      justify-content: space-between;
      .advert-effect-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
        margin-top: 10%;
      }
      .advert-effect-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px*$big;
        margin-top: 21px*$big;
      }
      .advert-effect-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px*$big;
        .advert-effect-start{
           background-image: url('../img/advert-effect-start.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 440px*$big;
          height: 440px*$big;
          display: flex;
          flex-direction: column;
          align-items: center;
          .advert-effect-header{
            width: 57px*$big;
            height: 57px*$big;
            margin-top: 152px*$big;
          }
          .advert-effect-text{
            font-size: 20px*$big;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 33px*$big;
            width: 170px*$big;
            margin-top: 15px*$big;
          }
        }
        .advert-effect-bottombg{
          background-image: url('../img/advert-effect-bottombg.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 1021px*$big;
          height: 265px*$big;
          margin-top: -190px*$big;
          position: relative;
          top: 0;
          left: 0;
          .advert-effect-bottom-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: absolute;
            left: 51px*$big;
            top: -135px*$big;
            .advert-effect-item-title{
              background-image: url('../img/advert-effect-item-active.png');
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px*$big;
              height: 93px*$big;
              color: #FFFFFF;
              z-index: 9;
              cursor: pointer;
              position: absolute;
              transform-style: preserve-3d;
              backface-visibility: hidden;
              transition: all 1s;
              transform: rotateY(0);
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 5px*$big;
              margin-top: 2px*$big;
              .advert-effect-item-title-top{
                width: 35px*$big;
                height: 35px*$big;
                margin-top: 11px*$big;
                font-size: 16px*$big;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px*$big;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FF7800;
                line-height: 24px*$big;
                
              }
            } 
            .advert-effect-item-line{
              width: 1px*$big;
              height: 100px*$big;
              background: #FF7800;
              margin-top: -20px*$big;
            }
            .advert-effect-item-round{
              width: 21px*$big;
              height: 21px*$big;
            }
            .advert-effect-item-active{
              background-image: url('../img/advert-effect-item-title.png');
              width: 183px*$big;
              height: 135px*$big;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 0px;
              transform: rotateY(-180deg);
              .advert-effect-item-title-top{
                margin-top: 40px*$big;
                width: 100%;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 15px*$big;
                margin-top: -5px*$big;
                // transform: scale(.8);
                width: 100%;
                text-align: center;
                opacity: 0;
                margin-left: 5px*$big;
              }
              .font-top{
                margin-top: -12px*$big;
              }
            }
            .advert-effect-item-title-warp{
              width: 183px*$big;
              height: 135px*$big;
              margin-left: -5px*$big;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .advert-effect-item-title-warp-active{
                .advert-effect-item-title{
                  transform: rotateY(-180deg);
                }
                .advert-effect-item-active{
                  transform: rotateY(0);
                  .advert-effect-item-title-bottom{
                    opacity: 1;
                  }
                }
              }
          }
          
          .advert-effect-item-one{
              left: 210px*$big;
              top: -225px*$big;
              .advert-effect-item-line{
                height: 166px*$big;
              }
              .advert-effect-item-title{
                .advert-effect-item-line{
                  margin-top: 0;
                }
              }
              
            }
          .advert-effect-item-two{
              left: 168px*$big;
              top: -18px*$big;
              z-index: 99;
              .advert-effect-item-line{
                height: 80px*$big;
              }
          }
          .advert-effect-item-three{
              left: 645px*$big;
              top: -210px*$big;
              .advert-effect-item-line{
                height: 147px*$big;
              }
          }
          .advert-effect-item-four{
              left: 774px*$big;
              top: -64px*$big;
          }
        }
        
        
      }
      .advert-effect-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        left: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
      }
    }
  }
  .section9{
  background-image: url('../img/section9-bg.png');
  background-size: cover;
  .section-profit{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .profit-title{
      margin-top: 10%;
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px*$big;
    }
    .profit-bar{
      font-size: 15px*$big;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px*$big;
      margin-top: 20px*$big;
    }
    .profit-content{
      display: flex;
      flex-direction: row;
       margin-bottom: 3%;
      .profit-left{
        display: flex;
        flex-direction: column;
        .profit-content-list{
          display: flex;
          flex-direction: column;
          .profit-content-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px*$big;
            .profit-content-item-header{
              width: 39px*$big;
              height: 44px*$big;
            }
            .profit-content-item-text{
              margin-left: 17px*$big;
              width: 270px*$big;
              font-size: 15px*$big;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px*$big;
            }
          }
        }
        .profit-bottom{
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px*$big;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 67px*$big;
          margin-top: 20px*$big;
          .profit-bottom-btn{
            background-image: url('../img/profit-bottom-btn.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 29px*$big;
            width: 160px*$big;
            height: 71px*$big;
            text-align: center;
            line-height: 50px*$big;
            font-size: 19px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .profit-right{
        // background-image: url('../img/profit-right-bg.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        width: 477px*$big;
        height: 477px*$big;
        display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px*$big;
        .profit-right-comtent{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 0;
          left: 0;
          margin-left: 70px*$big;
          .profit-right-comtent-img{
            width: 358px*$big;
            height: 358px*$big;
            z-index: 5;
          }
          .profit-right-comtent-bg{
            position: absolute;
            width: 477px*$big;
            height: 477px*$big;
            z-index: 1;
            animation: rotateWarp 4s linear infinite 2.5s;
          }
          .profit-right-comtent-sml{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 2;
            animation: rotateWarpSml 4s linear infinite 1.5s;
          }
          .profit-right-comtent-within{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 3;
          }
        }
      }
    }
  }
  @keyframes rotateWarp {
    0%{
       transform: rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}
  @keyframes rotateWarpSml{
    0%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  
  }
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
    }
  }
  }
}
@media screen and (min-width: 3600px){
  $big:2.8;
  .section6{
    background-image: url('../img/section6-bg.png');
    background-size: cover;
    .sectionWarp{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    .section-title{
      padding-top: 130px*$big;
      font-size: 31px*$big;
      padding-top: 10%;
    }
    .section-desc{
      font-size: 15px*$big;
      font-weight: 400;
    }
    .section-list{
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content:space-around;
      align-items: center;
      margin: 0 auto;
      margin-top: -50px*$big;
      position: relative;
      height: 532px*$big;
      .section-item{
        background-image: url('../img/section-list-item-bg-small.png');
        width: 305px*$big;
        height: 340px*$big;
        cursor: pointer;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        margin-top: 40px*$big;
        position: absolute;
        .section-item-header{
          width: 53px*$big;
          height: 53px*$big;
          margin-top: 28px*$big;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .section-item-title{
          font-size: 18px*$big;
          margin-top: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-bar{
          font-family: PingFang SC;
          font-weight: 400;
          color: #FF7800;
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-line{
          width: 167px*$big;
          height: 8px*$big;
          margin-top: 17px*$big;
        }
        .section-item-text{
          width: 240px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          text-align: center;
        }
        .section-item-btn{
            text-align: center;
            background-image: url('../img/section-item-btn.png');
            background-size: cover;
            width: 93px*$big;
            height: 47px*$big;
            background-repeat: no-repeat;
            line-height: 24px*$big;
            font-size: 12px*$big;
            margin-top: 10px*$big;
        }
        
      }
      .section-item-big{
        background-image: url('../img/section-list-item-bg.png');
        width: 532px*$big;
        height: 532px*$big;
        margin-top: 0;
        padding: 0;
        .section-item-header{
          margin-top: 135px*$big;
        }
        .section-item-title{
          font-size: 23px*$big;
          margin-top: 11px*$big;
          line-height: 36px*$big;
        }
        .section-item-bar{
          font-size: 11px*$big;
          line-height: 20px*$big;
        }
        .section-item-text{
         width: 260px*$big;
          margin-top: 14px*$big;
          font-size: 14px*$big;
          line-height: 20px*$big;
          transform: scale(1);
        }
      }
      .section-item-warp{
          min-width: 296px*$big;
          min-height: 340px*$big;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
      }
    }
  }
  .section7{
   background-image: url('../img/section7-bg.png');
    background-size: cover;
    display: flex;
    justify-content: center;
  .section-voice{
    display: flex;
    flex-direction: row;
    text-align: center;
    height: 100%;
    justify-content: space-around;
    padding-top: 10%;
    .section-voice-left{
      display: flex;
      flex-direction: column;
      width: 413px*$big;
      margin-top: 40px*$big;
      .section-voice-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
      }
      .section-voice-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #F47800;
        line-height: 24px*$big;
         margin-top: 21px*$big;
      }
      .section-voice-slogn{
        font-size: 27px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 67px*$big;
         margin-top: 70px*$big;
         min-width: 450px*$big;
         text-align: center;
      }
      .section-voice-slogn-name{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 48px*$big;
        text-align: right;
      }
      .section-voice-slogn-btn{
        margin: 0 auto;
        background-image: url('../img/section-voice-slogn-btn.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 200px*$big;
        height: 71px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 50px*$big;
        text-align: center;
        cursor: pointer;
        margin-top: 38px*$big;
      }
    }
    .section-voice-right{
      display: flex;
      flex-direction: column;
      margin-left: 130px*$big;
      align-items: center;
      transform: scale(.8);
      margin-top: -75px*$big;
      position: relative;
      .section-voice-phone-title{
        background-image: url('../img/section-voice-phone-title.png');
        background-size: cover;
        background-repeat: no-repeat;
        width: 268px*$big;
        height: 60px*$big;
        font-size: 17px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 32px*$big;
        line-height: 50px*$big;
        text-align: center;
      }
      .section-voice-phone{
        width: 290px*$big;
        height: 590px*$big;
        position: absolute;
        z-index: 2;
        top: 60px*$big;
      }
      .section-voice-phone-mp4{
        width: 369px*$big;
        height: 560px*$big;
        position: absolute;
        z-index: 1;
        top: 75px*$big;
        video{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  }
  .section8{
   background-image: url('../img/section8-bg.png');
    background-size: cover;
    .advert-effect{
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      position: relative;
      height: 100%;
      justify-content: space-between;
      .advert-effect-title{
        font-size: 31px*$big;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 36px*$big;
        margin-top: 10%;
      }
      .advert-effect-bar{
        font-size: 15px*$big;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px*$big;
        margin-top: 21px*$big;
      }
      .advert-effect-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px*$big;
        .advert-effect-start{
           background-image: url('../img/advert-effect-start.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 440px*$big;
          height: 440px*$big;
          display: flex;
          flex-direction: column;
          align-items: center;
          .advert-effect-header{
            width: 57px*$big;
            height: 57px*$big;
            margin-top: 152px*$big;
          }
          .advert-effect-text{
            font-size: 20px*$big;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 33px*$big;
            width: 170px*$big;
            margin-top: 15px*$big;
          }
        }
        .advert-effect-bottombg{
          background-image: url('../img/advert-effect-bottombg.png');
          background-size: cover;
          background-repeat: no-repeat;
          width: 1021px*$big;
          height: 265px*$big;
          margin-top: -190px*$big;
          position: relative;
          top: 0;
          left: 0;
          .advert-effect-bottom-item{
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            position: absolute;
            left: 51px*$big;
            top: -135px*$big;
            .advert-effect-item-title{
              background-image: url('../img/advert-effect-item-active.png');
              background-size: cover;
              background-repeat: no-repeat;
              width: 120px*$big;
              height: 93px*$big;
              color: #FFFFFF;
              z-index: 9;
              cursor: pointer;
              position: absolute;
              transform-style: preserve-3d;
              backface-visibility: hidden;
              transition: all 1s;
              transform: rotateY(0);
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 5px*$big;
              margin-top: 2px*$big;
              .advert-effect-item-title-top{
                width: 35px*$big;
                height: 35px*$big;
                margin-top: 11px*$big;
                font-size: 16px*$big;
                font-family: PingFang SC;
                font-weight: bold;
                color: #FFFFFF;
                line-height: 24px*$big;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FF7800;
                line-height: 24px*$big;
                
              }
            } 
            .advert-effect-item-line{
              width: 1px*$big;
              height: 100px*$big;
              background: #FF7800;
              margin-top: -20px*$big;
            }
            .advert-effect-item-round{
              width: 21px*$big;
              height: 21px*$big;
            }
            .advert-effect-item-active{
              background-image: url('../img/advert-effect-item-title.png');
              width: 183px*$big;
              height: 135px*$big;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-left: 0px;
              transform: rotateY(-180deg);
              .advert-effect-item-title-top{
                margin-top: 40px*$big;
                width: 100%;
              }
              .advert-effect-item-title-bottom{
                font-size: 12px*$big;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 15px*$big;
                margin-top: -5px*$big;
                // transform: scale(.8);
                width: 100%;
                text-align: center;
                opacity: 0;
                margin-left: 5px*$big;
              }
              .font-top{
                margin-top: -12px*$big;
              }
            }
            .advert-effect-item-title-warp{
              width: 183px*$big;
              height: 135px*$big;
              margin-left: -5px*$big;
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
            .advert-effect-item-title-warp-active{
                .advert-effect-item-title{
                  transform: rotateY(-180deg);
                }
                .advert-effect-item-active{
                  transform: rotateY(0);
                  .advert-effect-item-title-bottom{
                    opacity: 1;
                  }
                }
              }
          }
          
          .advert-effect-item-one{
              left: 210px*$big;
              top: -225px*$big;
              .advert-effect-item-line{
                height: 166px*$big;
              }
              .advert-effect-item-title{
                .advert-effect-item-line{
                  margin-top: 0;
                }
              }
              
            }
          .advert-effect-item-two{
              left: 168px*$big;
              top: -18px*$big;
              z-index: 99;
              .advert-effect-item-line{
                height: 80px*$big;
              }
          }
          .advert-effect-item-three{
              left: 645px*$big;
              top: -210px*$big;
              .advert-effect-item-line{
                height: 147px*$big;
              }
          }
          .advert-effect-item-four{
              left: 774px*$big;
              top: -64px*$big;
          }
        }
        
        
      }
      .advert-effect-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        left: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
      }
    }
  }
  .section9{
  background-image: url('../img/section9-bg.png');
  background-size: cover;
  .section-profit{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    .profit-title{
      margin-top: 10%;
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 36px*$big;
    }
    .profit-bar{
      font-size: 15px*$big;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px*$big;
      margin-top: 20px*$big;
    }
    .profit-content{
      display: flex;
      flex-direction: row;
       margin-bottom: 3%;
      .profit-left{
        display: flex;
        flex-direction: column;
        .profit-content-list{
          display: flex;
          flex-direction: column;
          .profit-content-item{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 25px*$big;
            .profit-content-item-header{
              width: 39px*$big;
              height: 44px*$big;
            }
            .profit-content-item-text{
              margin-left: 17px*$big;
              width: 270px*$big;
              font-size: 15px*$big;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 24px*$big;
            }
          }
        }
        .profit-bottom{
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px*$big;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 67px*$big;
          margin-top: 20px*$big;
          .profit-bottom-btn{
            background-image: url('../img/profit-bottom-btn.png');
            background-size: cover;
            background-repeat: no-repeat;
            margin-top: 29px*$big;
            width: 160px*$big;
            height: 71px*$big;
            text-align: center;
            line-height: 50px*$big;
            font-size: 19px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
      .profit-right{
        // background-image: url('../img/profit-right-bg.png');
        // background-size: cover;
        // background-repeat: no-repeat;
        width: 477px*$big;
        height: 477px*$big;
        display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px*$big;
        .profit-right-comtent{
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: 0;
          left: 0;
          margin-left: 70px*$big;
          .profit-right-comtent-img{
            width: 358px*$big;
            height: 358px*$big;
            z-index: 5;
          }
          .profit-right-comtent-bg{
            position: absolute;
            width: 477px*$big;
            height: 477px*$big;
            z-index: 1;
            animation: rotateWarp 4s linear infinite 2.5s;
          }
          .profit-right-comtent-sml{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 2;
            animation: rotateWarpSml 4s linear infinite 1.5s;
          }
          .profit-right-comtent-within{
            width: 477px*$big;
            height: 477px*$big;
            z-index: 3;
          }
        }
      }
    }
  }
  @keyframes rotateWarp {
    0%{
       transform: rotate(0deg);
    }
    100%{
        transform:rotate(360deg);
    }
}
  @keyframes rotateWarpSml{
    0%{
      transform: rotate(360deg);
    }
    100%{
      transform: rotate(0deg);
    }
  }
  
  }
  .section10{
  background-image: url('../img/section10-bg.png');
  background-size: cover;
  position: relative;
  .demonstration{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .demonstration-title{
      font-size: 31px*$big;
      font-family: PingFang SC;
      font-weight: bold;
      color: #FFFFFF;
      text-align: center;
      margin-top: 10%;
    }
    .demonstration-path{
        display: flex;
        flex-direction: row;
        margin-top: 34px*$big;
        .demonstration-path-item{
          display: flex;
          flex-direction: row;
          align-items: center;
          .demonstration-path-item-header{
            width: 29px*$big;
            height: 29px*$big;
          }
          .demonstration-path-item-text{
            font-size: 15px*$big;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 24px*$big;
            width: 75px*$big;
            margin-left: 4px*$big;
            text-align: center;
          }
          .demonstration-path-item-line{
            width: 88px*$big;
            height: 15px*$big;
            margin: 0 29px*$big;
          }
        }
      }
    .demonstration-content{
      display: flex;
      flex-direction: row;
      margin-top: 39px*$big;
      .demonstration-content-list{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 82px*$big;
        width: 250px*$big;
        .demonstration-content-item{
          display: flex;
          flex-direction: column;
          margin: 0 32px*$big;
          cursor: pointer;
          .demonstration-content-item-img{
            width: 60px*$big;
            height: 87px*$big;
          }
          .demonstration-content-item-text{
            font-size: 13px*$big;
            font-family: PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 24px*$big;
            text-align: center;
          }
          .fontColor{
            color: #FF7800;
          }
        }
      }
      .demonstration-content-phone{
        width: 280px*$big;
        height: 471px*$big;
        margin: 0 20px*$big;
        position: relative;
      }
      .picPhoneWarp{
        width: 280px*$big;
        height: 471px*$big;
        position: absolute;
        z-index: 99;
      }
      .carouselDiv{
        margin: 0 auto;
        margin-left: 2%;
        border-radius: 50px*$big;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        width: 96%;
        height: 471px*$big;
        position:absolute;
        img{
          border-radius: 50px*$big;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
      .el-carousel{
        border-radius: 50px*$big;
        width: 96%;
        margin: 0 auto;
        height: 471px*$big;
      }
    }
    .demonstration-btn{
        background-image: url('../img/advert-effect-btn.png');
        background-size: cover;
        width: 133px*$big;
        height: 66px*$big;
        text-align: center;
        line-height: 40px*$big;
        position: absolute;
        right: 60px*$big;
        bottom: 5%;
        color: #fff;
        cursor: pointer;
        font-size: 15px*$big;
    }
  }
  }
}
</style>

