import request from './requestaVoice'


// 登录/
// 广告主本地测试使用v2
// 广告主线上需要把 v2 全部改成 v1

// 新改登陆
export function voiceLogin(data) {
    return request({
        url: 'v1/user/login',
        method: 'post',
        data,
        urlType: 'api'
    })
}

// 获取验证码
export function getVoiceCode(mobile) {
    return request({
        url: 'v1/user/get-sms-code',
        method: 'get',
        params: { mobile },
        urlType: 'api'
    })
}

//注册
// 广告主本地使用  v1  代理转 v2
// 广告主线上需要把 v2 全部改成 v1
export function registerVoice(data) {
    return request({
        url: 'v1/user/register',
        method: 'post',
        data,
        urlType: 'api'
    })
}