/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 域名地址
 *
 */

let baseUrl = ''   //鱼玩接口
let hrefUrl = ''    //媒体后台url
let baseUrlVoice = '' //语音接口
let hrefUrlVoice = ''  //语音广告主url
const protocol = document.location.protocol
const domain = document.domain
let h = ''
if (protocol == 'http:') {
  h = 'http://'
} else {
  h = 'https://'
}
if (domain == 'wwwsdk.xiaozhuokeji.com') {
  // 新量象官网测试服
  baseUrl = h + 'media-api-test.xiaozhuokeji.com'
  hrefUrl = h + 'media-h5-test.xiaozhuokeji.com'
  baseUrlVoice = h + 'voice-api-test.xiaozhuokeji.com'
  hrefUrlVoice = h + 'voice-h5-test.xiaozhuokeji.com'
}else if (domain == 'wwwpre.xinliangxiang.com') {
  // 新量象官网预发
  baseUrl = h + 'mediapreapi.xinliangxiang.com' //媒体后台接口
  hrefUrl = h + 'mediapre.xinliangxiang.com'  //媒体后台域名
  baseUrlVoice = h + 'adverterpreapi.xinliangxiang.com' //广告主后台接口
  hrefUrlVoice = h + 'adverterpre.xinliangxiang.com' //广告主后台域名
} else if (domain == 'www.xinliangxiang.com') {
  // 新量象官网正式
  baseUrl = h + 'mediaapi.xinliangxiang.com'
  hrefUrl = h + 'media.xinliangxiang.com'
  baseUrlVoice = h + 'adverterapi.xinliangxiang.com'
  hrefUrlVoice = h + 'adverter.xinliangxiang.com'
} else {
  hrefUrl = h + 'localhost:9528'
  hrefUrlVoice = h + 'localhost:9600'
}

export { baseUrl, hrefUrl ,hrefUrlVoice,baseUrlVoice}
